export const newCustomer = {
  adSoyad: "",
  tckn: "",
  telefon: "",
  email: "",
  vdKodu: "",
  ticSicilNo: "",
  eArsivPortalKimlikNo: "",
  eArsivPortalPassword: "",
  yevmiye_tarihi:"",
  yevmiye_no:"",
  parasutClientId: "",
  parasutClientSecret: "",
  parasutEmail: "",
  access_token: "",
  refresh_token: "",
  parasutUsername: "",
  parasutPassword: "",
};

  

export const newTeamFriends = {
  ekip_arkadasi_adi_soyadi: "",
  telefon: "",
  email: "",
};


export const aylar = {
  1: 31,
  2: 28,
  3: 31,
  4: 30,
  5: 31,
  6: 30,
  7: 31,
  8: 31,
  9: 30,
  10: 31,
  11: 30,
  12: 31,
};