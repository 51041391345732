import {
  FormControl,
  MenuItem,
  Select,
  TableCell,
} from "@mui/material";
import React, { useState } from "react";
import ExportData from "../Commons/ExportData";
// import MyButton from "../Commons/MyButton";

const KarsitIncelemeIslemCell = ({
  aliciVknTckn,
  saticiVknTckn,
  year,
  month,
}) => {
  const [includeAllPeriods, setIncludeAllPeriods] = useState("False");
  return (
    <>
      <TableCell
        sx={{
          padding: "10px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textAlign: "center",
          minWidth: "90px",
          maxWidth: "90px",
        }}
        title="'Evet' seçili olursa dışa aktarılırken tüm dönemler dahil edilir!"
        align="center"
      >
        <FormControl
          sx={{ minWidth: "50%" }}
        >
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={includeAllPeriods}
            onChange={(e) => setIncludeAllPeriods(e.target.value)}
          >
            <MenuItem value={"False"}>Hayır</MenuItem>
            <MenuItem value={"True"}>Evet</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell
        sx={{
          padding: "10px",
          minWidth: "50px",
          maxWidth: "90px",
        }}
      >
        <ExportData
          url={`api/indirilecek_KDV/export_karsit_inceleme_list/?saticiVknTckn=${saticiVknTckn}&aliciVknTckn=${aliciVknTckn}&year_month=${year}-${month}&all_year_sales=${includeAllPeriods}`}
          dosyaAdi={`karsit_inceleme_listesi.docx`}
        />
      </TableCell>
    </>
  );
};

export default KarsitIncelemeIslemCell;
