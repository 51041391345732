import { Box } from "@mui/material";
import React, { useEffect } from "react";
import Select from "react-select";
import { useGetCustomersQuery } from "../../services/customers";

const customStyles = {
  menuList: (provided, state) => ({
    ...provided,
    maxHeight: "250px",
    overflow: "auto",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected
      ? "#454F5B"
      : state.isFocused
      ? "#D3D3D3"
      : "#fff",
    color: state.isSelected ? "#fff" : "#454F5B",
    padding: 10,
    position: "static",
    zIndex: 1000,
  }),
};

const CustomerSelect = ({ selectedMusteri, setSelectedMusteri, msg }) => {
  const { data: customers } = useGetCustomersQuery();

  // Müşterileri select seçeneklerine dönüştürme
  const musteriSelect = customers?.map((item) => ({
    value: item.tckn,
    label: `${item.ad_soyad} - ${item.tckn}`,
  }));

  // Sayfa açıldığında müşterinin varsayılan olarak seçilmemesini sağlamak
  useEffect(() => {
    // Eğer müşteri listesi varsa ve seçili müşteri boşsa, herhangi bir seçim yapmıyoruz.
    if (customers && !selectedMusteri) {
      setSelectedMusteri(null);
    }
  }, [customers, setSelectedMusteri, selectedMusteri]);

  return (
    <Box sx={{ position: "relative", zIndex: 5 }}>
      <Select
        value={selectedMusteri}
        onChange={setSelectedMusteri}
        options={musteriSelect}
        isClearable={true}
        styles={customStyles}
        className="mb-3"
        placeholder={msg}
      />
    </Box>
  );
};

export default CustomerSelect;
