// import { Navigate, Outlet } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { notify } from "../helper/notify";

// export const PrivateYMMRouter = () => {
//   const { currentUser } = useSelector((state) => state.auth);
//   if (
//     currentUser &&
//     (currentUser.kullanici_tipi === "ymm" ||
//       currentUser?.ekiparkadasi?.ekip_lideri_kullanici_tipi === "ymm")
//   ) {
//     return <Outlet />;
//   } else {
//     notify(
//       "Bu sayfayı görmeye yetkili değilsiniz! Paketler sayfasında yükseltme yapabilirsiniz!",
//       "warning"
//     );
//     return <Navigate to="/musavir-online/paket-yukselt/" />;
//   }
// };

// export const PrivateSMMRouter = () => {
//   const { currentUser } = useSelector((state) => state.auth);
//   if (
//     currentUser &&
//     (currentUser.kullanici_tipi === "smm" ||
//       currentUser?.ekiparkadasi?.ekip_lideri_kullanici_tipi === "smm")
//   ) {
//     return <Outlet />;
//   } else {
//     notify(
//       "Bu sayfayı görmeye yetkili değilsiniz! Paketler sayfasında yükseltme yapabilirsiniz!",
//       "warning"
//     );
//     return <Navigate to="/musavir-online/paket-yukselt/" />;
//   }
// };

// export const PrivateSMMOrYmmRouter = () => {
//   const { currentUser } = useSelector((state) => state.auth);
//   if (currentUser && (currentUser.kullanici_tipi === "smm" || currentUser.kullanici_tipi === "ymm") || (currentUser?.ekiparkadasi?.ekip_lideri_kullanici_tipi === "smm" || currentUser?.ekiparkadasi?.ekip_lideri_kullanici_tipi === "ymm")) {
//     return <Outlet />;
//   } else {
//     notify(
//       "Bu sayfayı görmeye yetkili değilsiniz! Paketler sayfasında yükseltme yapabilirsiniz!",
//       "warning"
//     );
//     return <Navigate to="/musavir-online/paket-yukselt/" />;
//   }
// };

// const PrivateRouter = () => {
//   const { currentUser } = useSelector((state) => state.auth);
//   return currentUser ? <Outlet /> : <Navigate to="/" />;
// };

// export default PrivateRouter;
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { notify } from "../helper/notify";

const hasAccess = (user, types) => {
  if (!user) return false;
  return (
    types.includes(user.kullanici_tipi) ||
    types.includes(user?.ekiparkadasi?.ekip_lideri_kullanici_tipi)
  );
};

const notifyUnauthorized = () => {
  notify(
    "Bu sayfayı görmeye yetkili değilsiniz! Paketler sayfasında yükseltme yapabilirsiniz!",
    "warning"
  );
};

export const PrivateYMMRouter = () => {
  const { currentUser } = useSelector((state) => state.auth);
  if (hasAccess(currentUser, ["ymm"])) {
    return <Outlet />;
  } else {
    notifyUnauthorized();
    return <Navigate to="/musavir-online/paket-yukselt/" />;
  }
};

export const PrivateSMMRouter = () => {
  const { currentUser } = useSelector((state) => state.auth);
  if (hasAccess(currentUser, ["smm"])) {
    return <Outlet />;
  } else {
    notifyUnauthorized();
    return <Navigate to="/musavir-online/paket-yukselt/" />;
  }
};

export const PrivateSMMOrYmmRouter = () => {
  const { currentUser } = useSelector((state) => state.auth);
  if (hasAccess(currentUser, ["smm", "ymm"])) {
    return <Outlet />;
  } else {
    notifyUnauthorized();
    return <Navigate to="/musavir-online/paket-yukselt/" />;
  }
};

const PrivateRouter = () => {
  const { currentUser } = useSelector((state) => state.auth);
  return currentUser ? <Outlet /> : <Navigate to="/" />;
};

export default PrivateRouter;
