import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";

const CustomersSalesPurchasesTable = ({ selectedMusteri }) => {
  const [alisSatısMusteriKayitlari, setAlisSatısMusteriKayitlari] = useState(
    []
  );
  const [errorAlisSatıs, setErrorAlisSatıs] = useState(false);
  const [loadingAlisSatıs, setLoadingAlisSatıs] = useState(false);
  const axiosWithToken = useAxios();
  useEffect(() => {
    if (selectedMusteri) {
      const fetchStokDurumu = async () => {
        const url = `api/indirilecek_KDV/get_buyer_seller_list/${selectedMusteri?.value}/`;
        setErrorAlisSatıs(false);
        setLoadingAlisSatıs(true);
        setAlisSatısMusteriKayitlari([]);
        try {
          const response = await axiosWithToken(url);
          if (response.status === 200) {
            const data = response.data;
            setAlisSatısMusteriKayitlari(data.data);
          }
        } catch (error) {
          setErrorAlisSatıs(error.response.data.error);
          setAlisSatısMusteriKayitlari([]);
        } finally {
          setLoadingAlisSatıs(false);
        }
      };

      fetchStokDurumu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMusteri]);
  return (
    <>
      <Typography
        component={"h3"}
        variant={"h5"}
        align="center"
        color="secondary.second"
        mt={2}
      >
        Alış - Satış Müşteri Kayıtları
      </Typography>
      {alisSatısMusteriKayitlari.length ? (
        <TableContainer
          component={Paper}
          color="secondary.second"
          className="myscrool"
          sx={{
            mt: 1,
            maxWidth: "100%",
            backgroundColor: "tableColor.main",
            position: "relative",
            maxHeight: "450px",
            minHeight: "450px",
            overflowY: "scroll",
          }}
        >
          <Table
            aria-label="simple table"
            sx={{
              color: "secondary.second",
              "& .MuiTableCell-root": {
                color: "secondary.second !important",
              },
              "& .MuiSvgIcon-root": {
                color: "secondary.second !important",
              },
              "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
                color: "secondary.second !important",
              },
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                top: "0px",
                zIndex: 1,
                backgroundColor: "tableColor.main",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "100px",
                  }}
                  title={"Ad Soyad/Ünvan"}
                  align="left"
                >
                  Ad Soyad/Ünvan <br />
                  VKN/TCKN{" "}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "100px",
                  }}
                  align="center"
                >
                  Adres
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "100px",
                  }}
                  align="center"
                >
                  İrtibat No
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "100px",
                  }}
                  align="right"
                >
                  e-mail Adresi
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alisSatısMusteriKayitlari.map((item, i) => (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      minWidth: "50px",
                      maxWidth: "100px",
                    }}
                    align="left"
                    component="th"
                    scope="row"
                    title={item["Ad Soyad/Ünvan"]}
                  >
                    {item["Ad Soyad/Ünvan"]}
                    <br />
                    {item["VKN/TCKN"]}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      minWidth: "50px",
                      maxWidth: "100px",
                    }}
                    title={item["Adres"]}
                    align="center"
                  >
                    {item["Adres"]}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      minWidth: "50px",
                      maxWidth: "100px",
                    }}
                    title={item["İrtibat No"]}
                    align="center"
                  >
                    {item["İrtibat No"] !== "None"
                      ? item["İrtibat No"] !== "nan"
                        ? item["İrtibat No"]
                        : "-"
                      : "-"}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      minWidth: "50px",
                      maxWidth: "100px",
                    }}
                    title={item["e-mail Adresi"]}
                    align="center"
                  >
                    {item["e-mail Adresi"] !== "None"
                      ? item["e-mail Adresi"] !== "nan"
                        ? item["e-mail Adresi"]
                        : "-"
                      : "-"}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}

      {loadingAlisSatıs ? <LoadingData /> : ""}
      {errorAlisSatıs ? <ErrorData error={errorAlisSatıs} /> : ""}
      {!errorAlisSatıs &&
      !alisSatısMusteriKayitlari.length &&
      !loadingAlisSatıs ? (
        <Typography component={"h4"} color="secondary.second" m={3}>
          Görüntülenecek alış - satış müşteri kayitlari verisi bulunamadı.
          Müşteriniz için envanter kaydı güncellemesi yapabilirsiniz.
        </Typography>
      ) : (
        ""
      )}
    </>
  );
};

export default CustomersSalesPurchasesTable;
