import LogoutIcon from "@mui/icons-material/Logout";
import MenuIcon from "@mui/icons-material/Menu";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { Outlet } from "react-router-dom";
import useAuthCall from "../hooks/useAuthCall";
import MenuListItems from "../components/Navigation/MenuListItems";
import IconButton from "@mui/material/IconButton";
import Brightness4Icon from "@mui/icons-material/Brightness4";
import Brightness7Icon from "@mui/icons-material/Brightness7";
const drawerWidth = 240;

const TelifHakki = ()=>{
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "end",
        height: "100%",
      }}
    >
      <Typography align="center" color="secondary.second" variant="caption">
        Copyright © 2024 Müşavir Online bir G-5 Tech ürünüdür.
        <br /> Bu uygulamanın tüm Hakları G-5 Tech Firmasına aittir.
      </Typography>
    </Box>
  );
}

function Dashboard(props) {
  const { logout } = useAuthCall();
  const { window, colorMode,theme } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", maxWidth: "100%" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          width: { md: `calc(100% - ${drawerWidth}px)` },
          ml: { md: `${drawerWidth}px` },
          backgroundColor: "primary.main",
          color: "#EEEEEE",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
            {/* Müşavir Online */}
          </Typography>
          <IconButton
            sx={{ ml: 1, my: 2 }}
            onClick={colorMode.toggleColorMode}
            color="inherit"
          >
            {theme.palette.mode === "dark" ? (
              <Brightness7Icon />
            ) : (
              <Brightness4Icon />
            )}
          </IconButton>
          <Button
            color="btnColor"
            sx={{
              textTransform: "none",
              "&:hover": {
                backgroundColor: "rgb(0, 146, 202,0.7)",
                color: "white",
                "&.MuiSvgIcon-root": {
                  color: "white",
                },
              },
            }}
            onClick={logout}
          >
            Çıkış Yap <LogoutIcon sx={{ ml: "0.5rem",color:"inherit" }} />
          </Button>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "primary.main",
            },
          }}
        >
          <MenuListItems />
          <TelifHakki />
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", md: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "primary.main",
            },
          }}
          open
        >
          <MenuListItems />
          <TelifHakki />
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          // p: 3,
          width: `calc(100% - ${drawerWidth}px)`,
        }}
      >
        <Toolbar />
        <Outlet />
        {/* <Box
          component="footer"
          sx={{
            mt: "auto",
            py: 2,
            position: "fixed",
            bottom: 0,
            backgroundColor: "#393E46",
            color: "#EEEEEE",
            textAlign: "center",
            width: "100%",
          }}
        >
          <Typography variant="body1">
            2024 © Müşavir Online bir G-5 Tech ürünüdür.
          </Typography>
        </Box> */}
      </Box>
    </Box>
  );
}

export default Dashboard;
