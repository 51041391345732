import Swal from "sweetalert2";

export const notify = (msg, icon) =>
  Swal.fire({
    title: "Müşavir Online",
    text: msg,
    icon: icon,
    timer: 3000,
    timerProgressBar: true,
    position: "center",
    confirmButtonText: "Tamam",
  });

export const  notifyHtml = (errors) => {
let errorMessage = "";
for (const [key, messages] of Object.entries(errors)) {
  errorMessage += `${key}:\n`;
  errorMessage += messages.join("\n");
  errorMessage += "\n\n";
}

// Hata mesajını SweetAlert2 ile göster
Swal.fire({
  title: "Hata!",
  text: errorMessage.trim(),
  icon: "error",
  confirmButtonText: "Tamam",
});
}