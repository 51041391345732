import React, {useState } from "react";
import PageHeader from "../components/Commons/PageHeader";
import MyButton from "../components/Commons/MyButton";
import { Box, Grid } from "@mui/material";
import CustomerRegistrationModal from "../components/Forms/CustomerForm";
import CustomerTable from "../components/Tables/CustomerTable";
import { newCustomer } from "../helper/initialData";
import Loading from "../components/Commons/Loading";
import { useGetCustomersQuery } from "../services/customers";


const Customers = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setInitialState(newCustomer);
  };

  const {isLoading:loading} = useGetCustomersQuery();

  const [initialState, setInitialState] = useState(newCustomer);

  if (loading) return <Loading />;
  return (
    <Box sx={{ p: 1 }}>
      <PageHeader text="Mükelleflerim" />
      <Grid container spacing={3} flexDirection={"column"} mt={1}>
        <Grid item xs={8} md={3}>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={handleOpen}
            title="Yeni Mükellef Kaydı Oluştur"
          />
        </Grid>
      </Grid>
      <CustomerTable
        handleOpen={handleOpen}
        setInitialState={setInitialState}
      />
      {open && (
        <CustomerRegistrationModal
          isVisible={open}
          toggleModal={handleClose}
          initialState={initialState}
        />
      )}
    </Box>
  );
};

export default Customers;
