import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";
const StokTable = ({ selectedMusteri, counterUrun }) => {
  const [stokDurumu, setStokDurumu] = useState([]);
  const [errorStok, setErrorStok] = useState(false);
  const [loadingStok, setLoadingStok] = useState(true);
  const axiosWithToken = useAxios();
  useEffect(() => {
    // Sadece currentTCKN değiştiğinde bu useEffect tetiklenecek
    if (selectedMusteri) {
      const fetchStokDurumu = async () => {
        const url = `api/indirilecek_KDV/get_stock_show/${selectedMusteri?.value}`;
        setErrorStok(false);
        setLoadingStok(true);
        setStokDurumu([]);
        try {
          const response = await axiosWithToken(url);
          if (response.status === 200) {
            const data = response.data;

            const stockData = data.data.map((item) => ({
              urunadi: item["URUN ADI"],
              miktar_alis: item["MIKTAR_alis"],
              miktar_satis: item["MIKTAR_satis"],
              guncelstock: item["Guncel Stok"],
            }));
            setStokDurumu(stockData);
          }
        } catch (error) {
          setErrorStok(error.response.data.error);
          setStokDurumu([]);
        } finally {
          setLoadingStok(false);
        }
      };

      fetchStokDurumu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMusteri, counterUrun]);
  return (
    <>
      <Typography
        component={"h3"}
        variant={"h5"}
        align="center"
        color="secondary.second"
        mt={2}
      >
        Stok Durumu
      </Typography>
      {stokDurumu.length ? (
        <TableContainer
          component={Paper}
          color="secondary.second"
          className="myscrool"
          sx={{
            mt: 1,
            maxWidth: "100%",
            backgroundColor: "tableColor.main",
            position: "relative",
            maxHeight: "450px",
            minHeight: "450px",
            overflowY: "scroll",
          }}
        >
          <Table
            aria-label="simple table"
            sx={{
              color: "secondary.second",
              "& .MuiTableCell-root": {
                color: "secondary.second !important",
              },
              "& .MuiSvgIcon-root": {
                color: "secondary.second !important",
              },
              "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
                color: "secondary.second !important",
              },
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                top: "0px",
                zIndex: 1,
                backgroundColor: "tableColor.main",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "150px",
                  }}
                  align="left"
                  title="ÜRÜN ADI"
                >
                  ÜRÜN ADI
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    textAlign: "center",
                    minWidth: "50px",
                    maxWidth: "100px",
                  }}
                  title="Güncel Stok"
                  align="center"
                >
                  Güncel Stok
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {stokDurumu.map((item, i) => (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      minWidth: "50px",
                      maxWidth: "150px",
                    }}
                    align="left"
                    component="th"
                    scope="row"
                    title={item.urunadi}
                  >
                    {item.urunadi}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textAlign: "center",
                      minWidth: "50px",
                      maxWidth: "100px",
                    }}
                    title={item.guncelstock}
                    align="center"
                  >
                    {item.guncelstock}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {loadingStok ? <LoadingData /> : ""}

      {errorStok ? <ErrorData error={errorStok} /> : ""}
      {!errorStok && !stokDurumu.length && !loadingStok ? (
        <Typography component={"h4"} color="secondary.second" m={3}>
          Görüntülenecek cari verisi bulunamadı. Müşteriniz için envanter kaydı
          güncellemesi yapabilirsiniz.
        </Typography>
      ) : (
        ""
      )}
    </>
  );
};

export default StokTable;
