import React, { useEffect, useState } from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableSortLabel,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";

const AllGcb = ({ currentTCKN }) => {
  const [allGcb, setAllGcb] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [errorGcb, setErrorGcb] = useState(false);
  const [loadingGcb, setLoadingGcb] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const axiosWithToken = useAxios();

  useEffect(() => {
    if (currentTCKN) {
      const fetchGcbDurumu = async () => {
        const url = `api/indirilecek_KDV/all_gcb?tckn=${currentTCKN}`;
        setErrorGcb(false);
        setLoadingGcb(true);
        try {
          const response = await axiosWithToken(url);
          if (response.status === 200) {
            const data = response.data;

            const transformedData = data.map((item) => ({
              beyannameNo: item["BEYANNAME NO"],
              beyannameTarihi: item["BEYANNAME TARIHI"],
              toplamFatura: item["TOPLAM FATURA"],
              dovizCinsi: item["DOVIZ CINSI"],
            }));
            setAllGcb(transformedData);
            setOriginalData(transformedData);
          } else {
            console.error("Veri yükleme hatası:", response.statusText);
          }
        } catch (error) {
          console.error("İstek sırasında hata oluştu:", error);
          setErrorGcb(true);
          setAllGcb([]);
        } finally {
          setLoadingGcb(false);
        }
      };

      fetchGcbDurumu();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTCKN]);

  const handleRequestSort = (property) => {
    if (orderBy === property) {
      // If the same column is clicked
      if (order === "asc") {
        setOrder("desc");
      } else if (order === "desc") {
        // Reset to the original state
        setOrder("");
        setOrderBy("");
        setAllGcb(originalData); // Reset to initial data
      } else {
        setOrder("asc");
      }
    } else {
      // If a new column is clicked, set to ascending order
      setOrder("asc");
      setOrderBy(property);
    }
  };

  const sortedData = () => {
    if (!orderBy) {
      return allGcb;
    }

    return allGcb.slice().sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  };

  return (
    <>
      <Typography
        component={"h3"}
        variant={"h5"}
        align="center"
        color="secondary.second"
        mt={2}
      >
        Gümrük Çıkış Beyannameleri
      </Typography>
      {allGcb.length ? (
        <TableContainer
          component={Paper}
          color="secondary.second"
          className="myscrool"
          sx={{
            mt: 1,
            width: "95%", // Increased width to occupy more space
            maxWidth: "95%", // Restricting maximum width to prevent overflow
            backgroundColor: "tableColor.main",
            position: "relative",
            maxHeight: "450px",
            minHeight: "450px",
            overflowY: "scroll",
            py: 1,
            margin: "auto", // Centering the table
          }}
        >
          <Table
            aria-label="simple table"
            sx={{
              color: "secondary.second",
              "& .MuiTableCell-root": {
                color: "secondary.second !important",
              },
              "& .MuiSvgIcon-root": {
                color: "secondary.second !important",
              },
            }}
          >
            <TableHead
              sx={{
                position: "sticky",
                top: "-15px",
                zIndex: 1,
                backgroundColor: "tableColor.main",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "180px", // Increased minWidth for better readability
                  }}
                  align="left"
                  title="BEYANNAME NO"
                  sortDirection={orderBy === "beyannameNo" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "beyannameNo"}
                    direction={orderBy === "beyannameNo" ? order : "asc"}
                    onClick={() => handleRequestSort("beyannameNo")}
                  >
                    BEYANNAME NO
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "180px", // Increased minWidth for better readability
                  }}
                  title="BEYANNAME TARIHI"
                  align="center"
                  sortDirection={orderBy === "beyannameTarihi" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "beyannameTarihi"}
                    direction={orderBy === "beyannameTarihi" ? order : "asc"}
                    onClick={() => handleRequestSort("beyannameTarihi")}
                  >
                    BEYANNAME TARIHI
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "180px", // Increased minWidth for better readability
                  }}
                  title="TOPLAM FATURA"
                  align="center"
                  sortDirection={orderBy === "toplamFatura" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "toplamFatura"}
                    direction={orderBy === "toplamFatura" ? order : "asc"}
                    onClick={() => handleRequestSort("toplamFatura")}
                  >
                    TOPLAM FATURA
                  </TableSortLabel>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "10px",
                    whiteSpace: "nowrap",
                    textAlign: "center",
                    minWidth: "150px", // Increased minWidth for better readability
                  }}
                  title="DOVIZ CINSI"
                  align="center"
                  sortDirection={orderBy === "dovizCinsi" ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === "dovizCinsi"}
                    direction={orderBy === "dovizCinsi" ? order : "asc"}
                    onClick={() => handleRequestSort("dovizCinsi")}
                  >
                    DOVIZ CINSI
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedData().map((item, i) => (
                <TableRow
                  key={i}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      minWidth: "180px", // Increased minWidth for better readability
                    }}
                    align="left"
                    component="th"
                    scope="row"
                    title={item.beyannameNo}
                  >
                    {item.beyannameNo}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      minWidth: "180px", // Increased minWidth for better readability
                    }}
                    title={item.beyannameTarihi}
                    align="center"
                  >
                    {item.beyannameTarihi}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      minWidth: "180px", // Increased minWidth for better readability
                    }}
                    title={item.toplamFatura}
                    align="center"
                  >
                    {item.toplamFatura}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "10px",
                      whiteSpace: "nowrap",
                      textAlign: "center",
                      minWidth: "150px", // Increased minWidth for better readability
                    }}
                    title={item.dovizCinsi}
                    align="center"
                  >
                    {item.dovizCinsi}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        ""
      )}
      {loadingGcb ? <LoadingData /> : ""}
      {errorGcb ? <ErrorData error={errorGcb} /> : ""}
      {!errorGcb && !allGcb.length && !loadingGcb ? (
        <Typography component={"h4"} color="secondary.second" m={3}>
          Görüntülenecek GCB verisi bulunamadı. Müşteriniz için gcb kaydı güncellemesi yapabilirsiniz.
        </Typography>
      ) : (
        ""
      )}
    </>
  );
};

export default AllGcb;
