import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Box, Typography, TextField, useTheme, Divider } from "@mui/material";
import useAxios from "../../hooks/useAxios";
import { notify } from "../../helper/notify";
import MyButton from "../Commons/MyButton";
import { customStyles } from "../Forms/UrunEslestirme";

function ProductCreationWizard({ currentTCKN, getData }) {
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [soldMaterials, setSoldMaterials] = useState([]);
  const [selectedSoldMaterials, setSelectedSoldMaterials] = useState(null);
  const axiosWithToken = useAxios();
  const theme = useTheme();
  useEffect(() => {
    if (currentTCKN) {
      fetchMaterials(currentTCKN);
      fetchSoldMaterials(currentTCKN);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTCKN]);

  const fetchMaterials = async (currentTCKN) => {
    try {
      const response = await axiosWithToken.get(
        `api/indirilecek_KDV/get_material_name/${currentTCKN}`
      );
      const formattedMaterials = response.data.data.map((item) => ({
        value: item["URUN ADI"],
        label: item["URUN ADI"],
        quantity: 1.0,
      }));
      setMaterials(formattedMaterials);
    } catch (error) {
      console.error("Malzemeler çekilirken bir hata oluştu:", error);
    }
  };

  const fetchSoldMaterials = async (currentTCKN) => {
    try {
      const response = await axiosWithToken.get(
        `api/indirilecek_KDV/get_material_sold/${currentTCKN}`
      );
      const formattedSoldMaterials = response.data.data.map((item) => ({
        value: item["URUN ADI"],
        label: item["URUN ADI"],
        quantity: 1.0,
      }));
      setSoldMaterials(formattedSoldMaterials);
    } catch (error) {
      console.error("Satılan malzemeler çekilirken bir hata oluştu:", error);
    }
  };

  const handleMaterialChange = (selectedOptions) => {
    setSelectedMaterials(selectedOptions || []);
  };

  const handleQuantityChange = (index, newQuantity) => {
    const updatedMaterials = selectedMaterials.map((material, idx) =>
      idx === index ? { ...material, quantity: newQuantity } : material
    );
    setSelectedMaterials(updatedMaterials);
  };

  const handleSoldMaterialChange = (selectedOption) => {
    setSelectedSoldMaterials(selectedOption);
  };

  const handleSubmit = async () => {
    const payload = {
      customerTCKN: currentTCKN,
      soldMaterials: selectedSoldMaterials.label
        ? [
            {
              name: selectedSoldMaterials.label,
              quantity: Number(selectedSoldMaterials.quantity),
              materials: selectedMaterials.map((material) => ({
                name: material.label,
                quantity: Number(material.quantity),
              })),
            },
          ]
        : null,
    };

    try {
      const response = await axiosWithToken.post(
        "api/indirilecek_KDV/save_product_creation/",
        payload
      );
      setSelectedMaterials([]);
      setSelectedSoldMaterials(null);
      if (!getData) {
        //* Satış faturaları listesi kısmında kullanıcı eşleştirme yaptıktan sonra ürünleri tekrar çekmeye gerek var mı ?
        // fetchMaterials();
        // fetchSoldMaterials();
      } else {
        getData();
      }
      //   alert("Veri başarıyla kaydedildi.");
      notify(response.data?.message, "success");
    } catch (error) {
      console.error("Hata:", error);
    }
  };

  const animatedComponents = makeAnimated();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        border: `1px solid ${theme.palette.secondary.second}`,
        borderRadius: "0.2rem",
        p: 2,
        backgroundColor: "tableColor.main",
      }}
    >
      <Typography variant="h5" color="secondary.second">
        İmalat Ürün Reçetesi
      </Typography>
      <Typography variant="h6" color="secondary.second">
        Ürün/Product (Satışlar)
      </Typography>
      <Select
        components={animatedComponents}
        options={soldMaterials}
        value={selectedSoldMaterials}
        onChange={handleSoldMaterialChange}
        placeholder="Satılan malzemeyi seçin"
        menuPlacement="top"
        styles={customStyles}
      />
      <Divider />
      <Typography variant="h6" color="secondary.second">
        Hammadde (Alışlar)
      </Typography>
      <Select
        components={animatedComponents}
        isMulti
        options={materials}
        value={selectedMaterials}
        onChange={handleMaterialChange}
        placeholder="Malzemeleri seçin"
        menuPlacement="top"
        styles={customStyles}
      />
      {selectedMaterials.map((material, index) => (
        <Box
          key={index}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography sx={{ mr: 2 }} color="secondary.second">
            {material.label}
          </Typography>
          <TextField
            label="Miktar"
            type="number"
            size="small"
            inputProps={{ min: 1 }}
            value={material.quantity}
            onChange={(e) =>
              handleQuantityChange(index, parseFloat(e.target.value))
            }
            sx={{ width: "100px", color: "secondary.second" }}
          />
        </Box>
      ))}

      <Box
        sx={{
          width: "25%",
          display: "flex",
          m: "auto",
        }}
      >
        <MyButton
          variant="contained"
          color="btnActiveColor"
          onClick={handleSubmit}
          title="Kaydet"
        />
      </Box>
    </Box>
  );
}

export default ProductCreationWizard;
