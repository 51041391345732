
export const envanterColumns = [
    {
        Header: 'SENARYO BİLGİSİ',
        accessor: 'SENARYO BILGISI',
        filterable: true,
        isSort: true,
        width: 500,
        minWidth: 0
    },
    {
        Header: 'FATURA TİPİ',
        accessor: 'FATURA TIPI'
    },
    {
        Header: 'FATURA TARİHİ',
        accessor: 'FATURA TARIHI'
    },
    {
        Header: 'FATURA NO',
        accessor: 'FATURA NO',
    },
    {
        Header: 'İRSALİYE TARİHİ',
        accessor: 'IRSALIYE TARIHI',
        width: 200, // Başlangıç genişliği
        minWidth: 100,
    },
    {
        Header: 'İRSALİYE NO',
        accessor: 'IRSALIYE NO',
        width: 20, // Başlangıç genişliği
        minWidth: 100,
    },
    {
        Header: 'ALICI ADI',
        accessor: 'ALICI ADI'
    },
    {
        Header: 'ALICI VKN/TCKN',
        accessor: 'ALICI VKN/TCKN'
    },
    {
        Header: 'ÜRÜN ADI',
        accessor: 'URUN ADI'
    },
    {
        Header: 'ÜRÜN KODU',
        accessor: 'URUN KODU'
    },
    {
        Header: 'GTIP',
        accessor: 'GTIP'
    },
    {
        Header: 'MİKTAR',
        accessor: 'MIKTAR'
    },
    {
        Header: 'MALIN CİNSİ',
        accessor: 'MALIN CINSI'
    },
    {
        Header: 'BİRİM FİYAT',
        accessor: 'BIRIM FIYAT'
    },
    {
        Header: 'DÖVİZ BİRİMİ',
        accessor: 'DOVIZ BIRIMI'
    },
    {
        Header: 'İNDİRİM',
        accessor: 'INDIRIM'
    },
    {
        Header: 'VERGİ ORANI',
        accessor: 'VERGI ORANI'
    },
    {
        Header: 'SATIŞ TUTARI',
        accessor: 'SATIS TUTARI'
    },
    {
        Header: 'ÜRÜN KDV TUTARI',
        accessor: 'URUN KDV TUTARI'
    },
    {
        Header: 'MAL HİZMET TOPLAM TUTAR',
        accessor: 'MAL HIZMET TOPLAM TUTAR'
    },
    {
        Header: 'TOPLAM KDV TUTARI',
        accessor: 'TOPLAM KDV TUTARI'
    },
    {
        Header: 'TEVKİFAT ORANI',
        accessor: 'TEVKIFAT ORANI'
    },
    {
        Header: 'TEVKİFAT SONRASI KALAN KDV',
        accessor: 'TEVKIFAT SONRASI KALAN KDV'
    },
    {
        Header: 'ÖDENECEK TUTAR',
        accessor: 'ODENECEK TUTAR'
    },
    {
        Header: 'ALICI ADRES',
        accessor: 'ALICI ADRES',
        Cell: ({ value }) => {
            const MAX_LENGTH = 13; // Maksimum görüntülenecek karakter sayısı
            const displayText = value?.length > MAX_LENGTH ? `${value.substring(0, MAX_LENGTH)}...` : value;

            return <span title={value}>{displayText}</span>;
        }
    },
    {
        Header: 'ALICI TELEFON',
        accessor: 'ALICI TELEFON'
    },
    {
        Header: 'ALICI VERGI DAIRESI',
        accessor: 'ALICI VERGI DAIRESI'
    },
    {
        Header: 'ALICI E-MAİL',
        accessor: 'ALICI E-MAİL'
    },
    {
        Header: 'SATICI ADI',
        accessor: 'SATICI ADI',
        Cell: ({ value }) => {
            const MAX_LENGTH = 13; // Maksimum görüntülenecek karakter sayısı
            const displayText = value?.length > MAX_LENGTH ? `${value.substring(0, MAX_LENGTH)}...` : value;

            return <span title={value}>{displayText}</span>;
        }
    },
    {
        Header: 'SATICI VKN/TCKN',
        accessor: 'SATICI VKN/TCKN'
    },
    {
        Header: 'SATICI ADRES',
        accessor: 'SATICI ADRES',
        Cell: ({ value }) => {
            const MAX_LENGTH = 13; // Maksimum görüntülenecek karakter sayısı
            const displayText = value?.length > MAX_LENGTH ? `${value.substring(0, MAX_LENGTH)}...` : value;

            return <span title={value}>{displayText}</span>;
        }
    },
    {
        Header: 'SATICI TELEFON',
        accessor: 'SATICI TELEFON'
    },
    {
        Header: 'SATICI VERGI DAIRESI',
        accessor: 'SATICI VERGI DAIRESI',
        Cell: ({ value }) => {
            const MAX_LENGTH = 13; // Maksimum görüntülenecek karakter sayısı
            const displayText = value?.length > MAX_LENGTH ? `${value.substring(0, MAX_LENGTH)}...` : value;

            return <span title={value}>{displayText}</span>;
        }
    },
    {
        Header: 'SATICI E-MAİL',
        accessor: 'SATICI E-MAİL'
    },
    {
        Header: 'MIKTAR ve CINSI',
        accessor: 'Miktar ve Cinsi'
    },
];

export const indirilecekKDVColumns = [
    {
        Header: "Fatura Tarihi",
        accessor: "faturaTarihi",
    },
    {
        Header: "Alış Faturasının Serisi",
        accessor: "Alisfaturasininserisi",
    },
    {
        Header: "Fatura No",
        accessor: "faturaNo",
    },
    {
        Header: "Satıcı Adı",
        accessor: "saticiAdi",
    },
    {
        Header: "Satıcı VKN/TCKN",
        accessor: "saticiVknTckn",
    },
    {
        Header: "Malın Cinsi",
        accessor: "malinCinsi",
    },
    {
        Header: "Miktar",
        accessor: "miktar",
    },
    {
        Header: "Mal Hizmet Toplam Tutar",
        accessor: "malHizmetToplamTutar",
    },
    {
        Header: "Toplam KDV Tutarı",
        accessor: "toplamKdvTutari",
    },
    {
        Header: "GÇB Tescil No'su (Alış İthalat İse)",
        accessor: "gcbtescilnosu",
    },
    {
        Header: "Belgenin İndirim Hakkının Kullanıldığı KDV Dönemi",
        accessor: "tabiolunandonem",
    },
];

export const gcbColumns = [
    {
        Header: "BEYANNAME NO",
        accessor: "BEYANNAME NO",
    },
    {
        Header: "SATICI VKN/TCKN",
        accessor: "SATICI VKN/TCKN",
    },
    {
        Header: "BEYANNAME TARİHİ",
        accessor: "BEYANNAME TARIHI",
    },
    {
        Header: "TOPLAM FATURA",
        accessor: "TOPLAM FATURA",
    },
    {
        Header: "FATURA MIKTARI",
        accessor: "FATURA MİKTARI",
    },
    {
        Header: "DÖVİZ CİNSİ",
        accessor: "DOVIZ CINSI",
    },
    {
        Header: "ALICI TİPİ",
        accessor: "ALICI TIPI",
    },
    {
        Header: "ALICI ÜLKE KODU",
        accessor: "ALICI ULKE KODU",
    },
    {
        Header: "ALICI ADI/UNVANI",
        accessor: "ALICI ADI UNVANI",
    },
    {
        Header: "ALICI ADRES",
        accessor: "ALICI ADRES",
        Cell: ({ value }) => {
            const MAX_LENGTH = 30; // Maksimum görüntülenecek karakter sayısı
            const isLongText = value && value.length > MAX_LENGTH;
            const displayText = isLongText
                ? `${value.substring(0, MAX_LENGTH)}...`
                : value;

            return <span title={value}>{displayText}</span>;
        },
    },
    {
        Header: "ALICI TELEFON",
        accessor: "ALICI TELEFON",
    },
    {
        Header: "RefID",
        accessor: "RefID",
    },
    {
        Header: "IMALATCI VKN/TCKN",
        accessor: "IMALATCI VKN/TCKN",
    },
    {
        Header: "GTIP",
        accessor: "GTIP",
    },
    {
        Header: "IMALATCI FIRMA",
        accessor: "IMALATCI FIRMA",
    },
    {
        Header: "ÜRÜN ADI",
        accessor: "URUN ADI",
    },
    {
        Header: "MİKTAR",
        accessor: "MIKTAR",
    },
    {
        Header: "CINSI",
        accessor: "CINSI",
    },
    {
        Header: "MALIN MİKTAR CİNSİ",
        accessor: "MALIN MIKTAR CINSI",
    },
    {
        Header: "ADET",
        accessor: "ADET",
    },
];

export const yuklenilecekKDVColumns = [
    {
        Header: "Fatura Tarihi",
        accessor: "Alış Faturasının Tarihi",
    },
    {
        Header: "Fatura No",
        accessor: "Alış Faturasının Sıra No'su",
    },
    {
        Header: "Satıcı Adı",
        accessor: "Satıcının Adı - Soyadı / Ünvanı",
    },
    {
        Header: "Satıcı VKN/TCKN",
        accessor: "Satıcının Vergi Kimlik Numarası / TC Kimlik Numarası",
    },
    {
        Header: "Malın/Hizmetin Cinsi",
        accessor: "Alınan Mal ve/veya Hizmetin Cinsi",
    },
    {
        Header: "Miktar",
        accessor: "Alınan Mal ve/veya Hizmetin Miktarı",
    },
    {
        Header: "Mal/Hizmet Tutarı (KDV Hariç)",
        accessor: "Alınan Mal ve/veya Hizmetin KDV Hariç Tutarı",
    },
    {
        Header: "KDV Tutarı",
        accessor: "KDV'si",
    },
    {
        Header: "Giren Mal/Hizmet KDV",
        accessor: "Bünyeye Giren Mal ve/veya Hizmetin KDV'si",
    },
    {
        Header: "İthalat Tescil No",
        accessor: "GGB Tescil No'su (Alış İthalat İse)",
    },
    {
        Header: "İade Hakkı Doğuran İşlem Türü",
        accessor: "Belgeye İlişkin İade Hakkı Doğuran İşlem Türü",
    },
    {
        Header: "Yüklenim Türü",
        accessor: "Yüklenim Türü",
    },
    {
        Header: "İndirime Konu Edildiği KDV Dönemi",
        accessor: "Belgenin İndirime Konu Edildiği KDV Dönemi",
    },
    {
        Header: "Yüklenildiği KDV Dönemi",
        accessor: "Belgenin Yüklenildiği KDV Dönemi",
    },
    {
        Header: "Aracın Plaka No",
        accessor: "Aracın Plaka Numarası",
    },
    {
        Header: "Aracın Şasi No",
        accessor: "Aracın Şasi Numarası",
    },
];


export const satisFaturalariColumns = [
    {
        Header: "Gümrük Çıkış Beyannamesi / ETGB-BGB Tescil Numarası",
        accessor: "Gümrük Çıkış Beyannamesi / ETGB-BGB Tescil Numarası",
    },
    {
        Header: "İhraç Edilen Malın Cinsi",
        accessor: "İhraç Edilen Malın Cinsi",
    },
    {
        Header: "İhraç Edilen Malın Miktarı",
        accessor: "İhraç Edilen Malın Miktarı",
    },
    {
        Header: "İhraç Edilen Malın Ölçü Birimi Kodu",
        accessor: "İhraç Edilen Malın Ölçü Birimi Kodu",
    },
    {
        Header: "Satış Faturasının Tarihi",
        accessor: "Satış Faturasının Tarihi",
    },
    {
        Header: "Satış Faturasının Serisi",
        accessor: "Satış Faturasının Serisi",
    },
    {
        Header: "Satış Faturasının Sıra No'su",
        accessor: "Satış Faturasının Sıra No'su",
    },
    {
        Header: "Alıcının Adı-Soyadı / Ünvanı",
        accessor: "Alıcının Adı-Soyadı / Ünvanı",
    },
    {
        Header: "GÇB / ETGB-BGB",
        accessor: "GÇB / ETGB-BGB",
    },
    {
        Header: "İade Hakkı Doğuran İşlem Türü",
        accessor: "İade Hakkı Doğuran İşlem Türü",
    },
];
export const ihracSatisFaturalariColumns = [
    {
        Header: "İhraç Kayıtlı Satış Faturasının Tarihi",
        accessor: "İhraç Kayıtlı Satış Faturasının Tarihi",
    },
    {
        Header: "İhraç Kayıtlı Satış Faturasının Serisi",
        accessor: "İhraç Kayıtlı Satış Faturasının Serisi",
    },
    {
        Header: "İhraç Kayıtlı Satış Faturasının Sıra No’su",
        accessor: "İhraç Kayıtlı Satış Faturasının Sıra No’su",
    },
    {
        Header: "Alıcının Adı Soyadı/Ünvanı",
        accessor: "Alıcının Adı Soyadı/Ünvanı",
    },
    {
        Header: "Alıcının Vergi Kimlik Numarası / T.C. Kimlik Numarası",
        accessor: "Alıcının Vergi Kimlik Numarası / T.C. Kimlik Numarası",
    },
    {
        Header: "Malın Cinsi",
        accessor: "Malın Cinsi",
    },
    {
        Header: "Miktar Kodu",
        accessor: "Miktar Kodu",
    },
    {
        Header: "Malın Kdv Hariç Tutarı",
        accessor: "Malın Kdv Hariç Tutarı",
    },
    {
        Header: "Malın Kdv’si",
        accessor: "Malın Kdv’si",
    },
    {
        Header: "İhracatçı Tarafından Yurt Dışına Düzenlenen Satış Faturasının Tarihi (GÇB/ETGB-BGB Üzerindeki Bilgiler)",
        accessor: "İhracatçı Tarafından Yurt Dışına Düzenlenen Satış Faturasının Tarihi (GÇB/ETGB-BGB Üzerindeki Bilgiler)",
    },
    {
        Header: "GÇB/ETGB-BGB Tescil No",
        accessor: "GÇB/ETGB-BGB Tescil No",
    },
    {
        Header: "GÇB/ETGB-BGB",
        accessor: "GÇB/ETGB-BGB",
    },
    {
        Header: "Malın Miktarı",
        accessor: "Malın Miktarı",
    },
];

export const tevkifatliSatisFaturalariListesiColumns = [
    {
        Header: "Sıra No",
        accessor: "Sıra No",
    },
    {
        Header: "Satış Faturasının Tarihi",
        accessor: "Satış Faturasının Tarihi",
    },
    {
        Header: "Satış Faturasının Serisi",
        accessor: "Satış Faturasının Serisi",
    },
    {
        Header: "Satış Faturasının Sıra No'su",
        accessor: "Satış Faturasının Sıra No'su",
    },
    {
        Header: "Alıcının Adı-Soyadı / Ünvanı",
        accessor: "Alıcının Adı-Soyadı / Ünvanı",
    },
    {
        Header: "Alıcının Vergi Kimlik Numarası / TC Kimlik Numarası",
        accessor: "Alıcının Vergi Kimlik Numarası / TC Kimlik Numarası",
    },
    {
        Header: "Satılan Mal ve/veya Hizmetin Cinsi",
        accessor: "Satılan Mal ve/veya Hizmetin Cinsi",
    },
    {
        Header: "Satılan Mal ve/veya Hizmetin Miktarı",
        accessor: "Satılan Mal ve/veya Hizmetin Miktarı",
    },
    {
        Header: "Satılan Mal ve/veya Hizmetin KDV Hariç Tutarı",
        accessor: "Satılan Mal ve/veya Hizmetin KDV Hariç Tutarı",
    },
    {
        Header: "KDV'si",
        accessor: "KDV'si",
    },
    {
        Header: "Yapılan Tevkifat Tutarı",
        accessor: "Yapılan Tevkifat Tutarı",
    },
    {
        Header: "İade Hakkı Doğuran İşlem Türü",
        accessor: "İade Hakkı Doğuran İşlem Türü",
    },
];

export const iadeyeEsasKDVColumns = [
    {
        Header: "Atik'ler Nedeniyle Yüklenilen Vergiden İade Hesabına Verilen Pay",
        accessor:
            "Atik'ler Nedeniyle Yüklenilen Vergiden İade Hesabına Verilen Pay",
    },
    {
        Header: "Dönem",
        accessor: "Dönem",
    },
    {
        Header: "Genel İmal ve İdare Giderlerinden İade Hesabına Verilen Pay",
        accessor: "Genel İmal ve İdare Giderlerinden İade Hesabına Verilen Pay",
    },
    {
        Header: "Sıra No",
        accessor: "Sıra No",
    },
    {
        Header: "İade Hakkı Doğuran İşlem Bedeli",
        accessor: "İade Hakkı Doğuran İşlem Bedeli",
    },
    {
        Header: "İade Edilebilir Azami Vergi",
        accessor: "İade Edilebilir Azami Vergi",
    },

    {
        Header: "İade Hakkı Doğuran İşlem Türü",
        accessor: "İade Hakkı Doğuran İşlem Türü",
    },
    {
        Header: "İşlemin Bünyesine Doğrudan Giren Vergiler",
        accessor: "İşlemin Bünyesine Doğrudan Giren Vergiler",
    }
];

export const kdvBeyannameColumns = [
    {
        Header: "Vergi No",
        accessor: "vergi_no",
    },
    {
        Header: "Yıl",
        accessor: "yıl",
    },
    {
        Header: "Ay",
        accessor: "ay",
    },
    {
        Header: "Matrah",
        accessor: "matrah",
    },
    {
        Header: "Hesaplanan KDV",
        accessor: "hesaplanan_kdv",
    },
    {
        Header: "Önceki Dönem KDV",
        accessor: "onceki_donem_kdv",
    },
    {
        Header: "Bu Dönem KDV",
        accessor: "bu_donem_kdv",
    },
    {
        Header: "Sorumlu Sıfat Beyan",
        accessor: "sorumlu_sifat_beyan",
    },
    {
        Header: "Toplam KDV",
        accessor: "toplam_kdv",
    },
    {
        Header: "Yuklenilecek KDV",
        accessor: "yuklenilecek_kdv",
    },
    {
        Header: "Devreden KDV",
        accessor: "devreden_kdv",
    },
    {
        Header: "Tam İstisna Mal İhracatı",
        accessor: "tam_istisna_mal_ihracati",
    },
    {
        Header: "Tam İstisna Diğer",
        accessor: "tam_istisna_diger",
    },
];