import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios";
import KarsitIncelemeIslemCell from "./KarsitIncelemeIslemCell";
import MyButton from "../Commons/MyButton";
import { aylar } from "../../helper/initialData";
import KarsitIncelemeInfo from "./KarsitIncelemeInfo";

const icon = {
  EVET: "✅",
  HAYIR: "❌",
};

const KarsitIncelemeListesi = ({
  karsitIncelemeData,
  aliciVknTckn,
  setKarsitIncelemeData,
  year,
  month,
}) => {
  const [errorStok, setErrorStok] = useState(false);
  const [loadingStok, setLoadingStok] = useState(true);
  const [karsitIncelemeInfo, setKarsitIncelemeInfo] = useState([]);
  const axiosWithToken = useAxios();
  const getKarsitInceleme = async () => {
    setLoadingStok(true);
    setErrorStok(false);
    try {
      const { data } = await axiosWithToken(
        `api/indirilecek_KDV/get_karsit_inceleme_list/${aliciVknTckn}?start_date=${year}-${month}-01&end_date=${year}-${month}-${aylar[month]}`
      );
      setKarsitIncelemeInfo(data);
      setKarsitIncelemeData(
        data[
          "Yalnızca soldaki listede yer alan satıcılar ile Karşıt İnceleme Tutanağı düzenlenmesi önerilir"
        ]
      );
    } catch (error) {
      setErrorStok(true);
    } finally {
      setLoadingStok(false);
    }
  };

  useEffect(() => {
    getKarsitInceleme();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Typography
        component={"h3"}
        variant={"h5"}
        align="center"
        color="secondary.second"
        mt={2}
      >
        Karşıt İnceleme Listesi Öneri Tablosu
      </Typography>
      <Grid container>
        <Grid item xs={10} md={8}>
          {karsitIncelemeData.length ? (
            <>
              <TableContainer
                component={Paper}
                color="secondary.second"
                className="myscrool"
                sx={{
                  my: 2,
                  maxWidth: "100%",
                  backgroundColor: "tableColor.main",
                  position: "relative",
                  minHeight: "500px",
                  maxHeight: "500px",
                  minWidth: "300px",
                  overflowY: "scroll",
                }}
              >
                <Table
                  aria-label="simple table"
                  sx={{
                    color: "secondary.second",
                    "& .MuiTableCell-root": {
                      color: "secondary.second !important",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "secondary.second !important",
                    },
                    "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar":
                      {
                        color: "secondary.second !important",
                      },
                    border: "1px solid secondary.second",
                  }}
                >
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: "-1px",
                      border: "1px solid secondary.second",
                      zIndex: 15,
                      backgroundColor: "tableColor.main",
                      boxShadow: "0px 1px 1px secondary.second",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "normal", // Allow text to wrap
                          overflow: "visible", // Display full text without hiding overflow
                          textOverflow: "clip", // Avoid ellipsis
                          textAlign: "left",
                          minWidth: "75px",
                        }}
                        align="left"
                      >
                        SATICI ADI
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "normal",
                          overflow: "visible",
                          textOverflow: "clip",
                          textAlign: "center",
                          minWidth: "100px",
                        }}
                        align="center"
                      >
                        SATICI TELEFON
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "normal",
                          overflow: "visible",
                          textOverflow: "clip",
                          textAlign: "center",
                          minWidth: "100px",
                        }}
                        align="center"
                      >
                        SATICI VKN/TCKN
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "normal",
                          overflow: "visible",
                          textOverflow: "clip",
                          textAlign: "center",
                          minWidth: "100px",
                        }}
                        align="center"
                      >
                        Yasal Zorunluluğu?
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "normal",
                          overflow: "visible",
                          textOverflow: "clip",
                          textAlign: "center",
                          minWidth: "150px",
                        }}
                        align="center"
                      >
                        %50 Orana Tamamlayıcılık
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "normal",
                          overflow: "visible",
                          textOverflow: "clip",
                          textAlign: "center",
                          minWidth: "200px",
                        }}
                        align="center"
                      >
                        Tüm Dönemler Dahil Edilsin mi?
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "normal",
                          overflow: "visible",
                          textOverflow: "clip",
                          minWidth: "100px",
                        }}
                      >
                        İşlem
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {karsitIncelemeData.map((item, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            minWidth: "75px",
                            maxWidth: "150px",
                          }}
                          align="left"
                          component="th"
                          scope="row"
                          title={item["SATICI ADI"]}
                        >
                          {item["SATICI ADI"]}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "left",
                            minWidth: "50px",
                            maxWidth: "50px",
                          }}
                          title={item["SATICI TELEFON"]}
                          align="center"
                        >
                          {item["SATICI TELEFON"]}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "left",
                            minWidth: "50px",
                            maxWidth: "100px",
                          }}
                          title={item["SATICI VKN/TCKN"]}
                          align="center"
                        >
                          {item["SATICI VKN/TCKN"]}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            minWidth: "40px",
                            maxWidth: "40px",
                          }}
                          // title={item["Yasal Zorunluluğu?"]}
                          align="center"
                        >
                          {icon[item["Yasal Zorunluluğu?"]]}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            minWidth: "40px",
                            maxWidth: "40px",
                          }}
                          // title={item["%50 Orana Tamamlayıcılık"]}
                          align="center"
                        >
                          {icon[item["%50 Orana Tamamlayıcılık"]]}
                        </TableCell>
                        <KarsitIncelemeIslemCell
                          // periods={item["FATURA TARIHI"].split(",")}
                          saticiVknTckn={item["SATICI VKN/TCKN"]}
                          aliciVknTckn={aliciVknTckn}
                          year={year}
                          month={month}
                          setKarsitIncelemeData={setKarsitIncelemeData}
                        />
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            ""
          )}

          {errorStok ? (
            <Typography component={"h4"} color="secondary.second" m={3}>
              Verilerinizi görüntülerken bir sorun oluştu. Daha sonra tekrar
              deneyiniz!
            </Typography>
          ) : (
            ""
          )}
          {!errorStok && !karsitIncelemeData.length && !loadingStok ? (
            <Typography component={"h4"} color="secondary.second" m={3}>
              Görüntülenecek cari verisi bulunamadı. Müşteriniz için envanter
              kaydı güncellemesi yapabilirsiniz.
            </Typography>
          ) : (
            ""
          )}
        </Grid>
        <Grid item xs={10} md={4}>
          <KarsitIncelemeInfo
            icon={icon}
            karsitIncelemeInfo={karsitIncelemeInfo}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1} justifyContent={"end"}>
        <Grid item>
          <MyButton
            title={"Kapat"}
            variant="contained"
            color="btnActiveColor"
            onClick={() => setKarsitIncelemeData([])}
          >
            Kapat
          </MyButton>
        </Grid>
      </Grid>
    </>
  );
};

export default KarsitIncelemeListesi;
