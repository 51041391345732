import { Button } from "@mui/material";
import React from "react";

const MyButton = (props) => {
  
  return (
    <Button
      sx={{
        width: props.width ? props.width :"100%",
        "&:hover": {
          backgroundColor: "rgb(0, 146, 202,0.7)",
          color: "primary.main",
        },
        color:
          props.color === "btnActiveColor" ? "white" : "secondary.second",
        textTransform: "none",
      }}
      {...props}
    >
      {props.title}
    </Button>
  );
};

export default MyButton;
