import axios from "axios";
import { useSelector } from "react-redux";

export const axiosWithPublic = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const useAxios = () => {
  const { token } = useSelector((state) => state.auth);

  const axiosWithToken = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    timeout: 20 * 60 * 1000,
    headers: { Authorization: `Token ${token}` },
  });

  return axiosWithToken;
};

export default useAxios;
