import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "../pages/Login";
import PrivateRouter, { PrivateSMMOrYmmRouter, PrivateSMMRouter, PrivateYMMRouter } from "./PrivateRouter";
import Dashboard from "../pages/Dashboard";
import Home from "../pages/Home";
import Customers from "../pages/Customers";
import Profile from "../pages/Profile";
import TeamFriends from "../pages/TeamFriends";
// import Paketler from "../pages/Paketler";
import MalIhracati from "../pages/MalIhracati";
import IhracKayitliTeslim from "../pages/IhracKayitliTeslim";
// import IndirimliOranaTabiKdv from "../pages/IndirimliOranaTabiKdv";
import TevkifatliSatislarIade from "../pages/TevkifatliSatislarIade";
// import UIuslararsıNakliyeIade from "../pages/UIuslararsıNakliyeIade";
import IslenmisFaturalar from "../pages/IslenmisFaturalar";
import IslemMerkezi from "../pages/IslemMerkezi";

const AppRouter = ({ theme, colorMode }) => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<Login theme={theme} colorMode={colorMode} />}
        />
        <Route path="musavir-online" element={<PrivateRouter />}>
          <Route
            path=""
            element={<Dashboard theme={theme} colorMode={colorMode} />}
          >
            <Route element={<PrivateYMMRouter />}>
              <Route path="mal-ihracati" element={<MalIhracati />} />
              <Route
                path="ihrac-kayitli-teslim"
                element={<IhracKayitliTeslim />}
              />
              <Route
                path="tevkifatli-satislarda-iade"
                element={<TevkifatliSatislarIade />}
              />
            </Route>
            <Route element={<PrivateSMMOrYmmRouter />}>
              <Route index element={<Home />} />
              <Route path="mukelleflerim" element={<Customers />} />
              <Route
                path="islenmis-faturalar"
                element={<IslenmisFaturalar />}
              />
              <Route path="ekip-arkadaslarim" element={<TeamFriends />} />
            </Route>
            <Route element={<PrivateSMMRouter />}>
              <Route path="islem-merkezi" element={<IslemMerkezi />} />
            </Route>
            {/* <Route
              path="indirimli-orana-tabi-kdv-listesi"
              element={<IndirimliOranaTabiKdv />}
            /> */}

            {/* <Route
              path="uluslararası-nakliye-iadesi"
              element={<UIuslararsıNakliyeIade />}
            /> */}
            <Route path="profilim" element={<Profile />} />

            {/* <Route path="paket-yukselt" element={<Paketler />} /> */}
          </Route>
        </Route>
      </Routes>
    </Router>
  );
};

export default AppRouter;
