import React, { useState } from "react";
import { Box } from "@mui/material";
import MyButton from "./MyButton";
import useAxios from "../../hooks/useAxios";
import { notify } from "../../helper/notify";

const ExportData = ({ url, dosyaAdi }) => {
  const axiosWithToken = useAxios();
  const [loading, setLoading] = useState(false);

  // Ses dosyalarını oluşturun
  const successSound = new Audio("/success.mp3");
  const errorSound = new Audio("/error.mp3");

  const handleExport = async () => {
    setLoading(true);
    try {
      const response = await axiosWithToken.get(url, {
        responseType: "blob",
        headers: {
          Accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        },
      });

      const blob = response.data;
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", dosyaAdi); // İndirilecek dosyanın adı
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      successSound.play();
    } catch (error) {
      console.error("İstek sırasında bir hata oluştu:", error);
      errorSound.play(); // Hata sesi çal
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "end",
        "& .Mui-disabled": {
          backgroundColor: "rgb(0, 146, 202,0.7)",
          color: "secondary.second",
          borderColor: "secondary.second",
        },
      }}
    >
      {loading ? (
        <MyButton
          variant="outlined"
          color="btnActiveColor"
          type="button"
          disabled
          title={"Hazırlanıyor..."}
        />
      ) : (
        <MyButton
          variant="contained"
          color="btnActiveColor"
          title="Dışa Aktar"
          onClick={handleExport}
        />
      )}
    </Box>
  );
};

export default ExportData;
