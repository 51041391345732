import React, { useEffect, useState } from "react";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import useAxios from "../../hooks/useAxios";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import { Close } from "@mui/icons-material";
import MyButton from "../Commons/MyButton";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  backgroundColor:"tableColor.main",
  width: "75%",
  boxShadow: 24,
  p: 4,
};
const AylıkKdvDetayTable = ({
  selectedMusteri,
  year,
  month,
  open,
  handleClose,
}) => {
  const [aylikDetayKDV, setAylikDetayKDV] = useState([]);
  const [errorKDV, setErrorKDV] = useState(false);
  const [loadingKDV, setLoadingKDV] = useState(true);
  const axiosWithToken = useAxios();
  useEffect(() => {
    if (selectedMusteri) {
      const fetchAylikKDV = async () => {
        const url = `api/customer_info/get_invoice_details/`;
        setLoadingKDV(true);
        setErrorKDV(false);
        try {
          const response = await axiosWithToken.post(url, {
            tckn: selectedMusteri?.value,
            year,
            month,
          });
          if (response.status === 200) {
            setAylikDetayKDV(response.data?.invoice_details);
            setLoadingKDV(false);
          } else {
            console.error("Veri yükleme hatası:", response.statusText);
          }
        } catch (error) {
          setErrorKDV(error?.response?.data?.error);
          setAylikDetayKDV([]);
        }finally{
            setLoadingKDV(false);
        }
      };

      fetchAylikKDV();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMusteri]);
  return (
    <>
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant={"body1"} color="secondary.second" mt={2}>
                Aylara Göre KDV Raporları ({year}/{month})
              </Typography>
              <IconButton onClick={handleClose}>
                <Close />
              </IconButton>
            </Box>
            {loadingKDV ? <LoadingData /> : ""}
            {errorKDV ? <ErrorData error={errorKDV} /> : ""}
            {aylikDetayKDV.length ? (
              <TableContainer
                component={Paper}
                color="secondary.second"
                className="myscrool"
                sx={{
                  mt: 1,
                  maxWidth: "100%",
                  backgroundColor: "tableColor.main",
                  position: "relative",
                  maxHeight: "450px",
                  minHeight: "450px",
                  overflowY: "scroll",
                }}
              >
                <Table
                  aria-label="simple table"
                  sx={{
                    color: "secondary.second",
                    "& .MuiTableCell-root": {
                      color: "secondary.second !important",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "secondary.second !important",
                    },
                    "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar":
                      {
                        color: "secondary.second !important",
                      },
                  }}
                >
                  <TableHead
                    sx={{
                      position: "sticky",
                      top: "0px",
                      zIndex: 1,
                      backgroundColor: "tableColor.main",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                          minWidth: "50px",
                          maxWidth: "150px",
                        }}
                        align="left"
                        title="İşlem Türü"
                      >
                        İşlem Türü
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                          minWidth: "50px",
                          maxWidth: "100px",
                        }}
                        title="Fatura"
                        align="center"
                      >
                        Fatura
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                          minWidth: "50px",
                          maxWidth: "100px",
                        }}
                        title="Açıklama"
                        align="center"
                      >
                        Açıklama
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                          minWidth: "50px",
                          maxWidth: "100px",
                        }}
                        title="Müşteri/Tedarikçi"
                        align="center"
                      >
                        Müşteri/Tedarikçi
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                          minWidth: "50px",
                          maxWidth: "100px",
                        }}
                        title="Düzenleme Tarihi"
                        align="center"
                      >
                        Düzenleme Tarihi
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "10px",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                          minWidth: "50px",
                          maxWidth: "100px",
                        }}
                        title="KDV"
                        align="center"
                      >
                        KDV
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {aylikDetayKDV.map((item, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            minWidth: "50px",
                            maxWidth: "150px",
                          }}
                          align="center"
                          component="th"
                          scope="row"
                          title={item["İşlem Türü"]}
                        >
                          {item["İşlem Türü"]}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            minWidth: "50px",
                            maxWidth: "100px",
                          }}
                          title={item["Fatura Numarası"]}
                          align="center"
                        >
                          {item["Fatura Numarası"]}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            minWidth: "50px",
                            maxWidth: "100px",
                          }}
                          title={item["Ürün Adı"]}
                          align="center"
                        >
                          {item["Ürün Adı"]}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            minWidth: "50px",
                            maxWidth: "100px",
                          }}
                          title={item["Müşteri/Tedarikçi"]}
                          align="center"
                        >
                          {item["Müşteri/Tedarikçi"]}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            minWidth: "50px",
                            maxWidth: "100px",
                          }}
                          align="center"
                          title={item["Fatura Tarihi"]}
                        >
                          {item["Fatura Tarihi"]}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "10px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            textAlign: "center",
                            minWidth: "50px",
                            maxWidth: "100px",
                            color:
                              item["KDV'si"] > 0
                                ? "green"
                                : item["KDV'si"] === 0
                                ? ""
                                : "red",
                          }}
                          align="center"
                          title={item["KDV'si"]}
                        >
                          {item["KDV'si"]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              ""
            )}
            <Box sx={{ my: 2, display: "flex", justifyContent: "end" }}>
              <MyButton
                title="Kapat"
                color="btnActiveColor"
                variant="contained"
                width="25%"
                onClick={handleClose}
              />
            </Box>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default AylıkKdvDetayTable;
