import React, { useState} from "react";
import PageHeader from "../components/Commons/PageHeader";
import MyButton from "../components/Commons/MyButton";
import { Box, Grid } from "@mui/material";
import TeamFriendsForm from "../components/Forms/TeamFriendsForm";
import TeamFriendsTable from "../components/Tables/TeamFriendsTable";
import { newTeamFriends } from "../helper/initialData";
import Loading from "../components/Commons/Loading";
import { useGetTeamMembersQuery } from "../services/teamMembers";


const TeamFriends = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setInitialState(newTeamFriends);
  };
  const {isLoading:loading} = useGetTeamMembersQuery()
  const [initialState, setInitialState] = useState(newTeamFriends);


  if (loading) return <Loading />;
  return (
    <Box sx={{ p: 1 }}>
      <PageHeader text="Ekip Arkadaşlarım" />
      <Grid container spacing={3} flexDirection={"column"} mt={1}>
        <Grid item xs={8} md={3}>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={handleOpen}
            title="Yeni Ekip Arkadaşı Ekle"
          />
        </Grid>
      </Grid>
      <TeamFriendsTable
        handleOpen={handleOpen}
        setInitialState={setInitialState}
      />
      {open && (
        <TeamFriendsForm
          isVisible={open}
          toggleModal={handleClose}
          initialState={initialState}
        />
      )}
    </Box>
  );
};

export default TeamFriends;
