import React, { useState } from 'react'
import { Grid,Box, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/tr";
import MyButton from "./MyButton";

const DateRangecomp = ({ getData,title }) => {
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, "month").startOf("month")
  );
  const [endDate, setEndDate] = useState(
    dayjs().subtract(1, "month").endOf("month")
  );
 const theme = useTheme()
  const handleData = (e)=>{
    e.preventDefault();
    getData(
      dayjs(startDate).format("YYYY-MM-DD"),
      dayjs(endDate).format("YYYY-MM-DD")
    );
  }


  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        pl: 2,
        width: "100%",
        mt: 2,
      }}
    >
      <Grid
        item
        xs={10}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          border: `1px solid ${theme.palette.secondary.second}`,
          borderRadius: "0.2rem",
          p: 2,
          m: "auto",
        }}
      >
        <Typography color="secondary.second" variant="h6">
          Oluşturmak İstediğiniz {title}ne Ait Dönemi Seçiniz
        </Typography>
        <Box component="form" onSubmit={handleData}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="Başlangıç Tarihi"
                value={startDate}
                views={["year", "month", "day"]}
                onChange={(newValue) => setStartDate(newValue)}
              />
              <DatePicker
                label="Bitiş Tarihi"
                value={endDate}
                views={["year", "month", "day"]}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </DemoContainer>
          </LocalizationProvider>

          <Box sx={{ width: "25%", mt: 2, mx: "auto" }}>
            <MyButton
              variant="contained"
              color="btnActiveColor"
              title="Oluştur"
              type="submit"
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DateRangecomp