import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MyButton from "../Commons/MyButton";
import useAxios from "../../hooks/useAxios";
import TableComponent from "../Tables/TableComponent";
import { iadeyeEsasKDVColumns } from "../../helper/columns";
// import ExportData from "../Commons/ExportData";
import EnvanterKaydi from "../Islemler/EnvanterKaydi";
// import YuklenimKaydi from "../Islemler/YuklenimKaydi";
import KDVBeyannamesiKaydi from "../Islemler/KDVBeyannamesiKaydi";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";
import GigTable from "../Tables/GigTable";
import ShowKDVBeyannameOzet from "../Islemler/ShowKDVBeyannameOzet";

const IadeyeEsasKDVHesaplama = ({ tckn }) => {
  const [iadeyeEsasKDVHesaplama, setIadeyeEsasKDVHesaplama] = useState([]);
  const [gigOranı, setGigOranı] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorData, setErrorData] = useState(false);
  const axiosWithToken = useAxios();

  const getData = async () => {
    setError(false);
    setErrorData(false);
    setLoading(true);
    setIadeyeEsasKDVHesaplama([]);
    try {
      const { data } = await axiosWithToken(
        `api/reader_3/mal_ihracati_kdv_hesap_tablosu/?tckn=${tckn}`
      );
      setIadeyeEsasKDVHesaplama(data["Mal İhracatı KDV Hesap Tablosu"]);
      setGigOranı(data["GİG Oran Tablosu"]);
      if (!data["Mal İhracatı KDV Hesap Tablosu"].length) {
        setErrorData(true);
      }
    } catch (error) {
      setError(error?.response?.data?.error);
      setIadeyeEsasKDVHesaplama([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (tckn) {
      getData();
    } else {
      setIadeyeEsasKDVHesaplama([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tckn]);

  return (
    <Box sx={{ p: 1 }}>
      <Typography color="error" variant="body2" mb={1}>
        *Eğer daha önce bu işlemleri yapmadıysanız lütfen önce yükleme
        işlemlerini tamamlayınız!
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1, gap: 1 }}>
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          <EnvanterKaydi tckn={tckn} />
        </Box>
        {/* <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          <YuklenimKaydi tckn={tckn} />{" "}
        </Box> */}
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          <KDVBeyannamesiKaydi tckn={tckn} getIadeyeEsasKDV={getData} />
        </Box>
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          <ShowKDVBeyannameOzet tckn={tckn} showBtn={true} />
        </Box>
      </Box>

      {loading ? <LoadingData /> : ""}
      {error ? <ErrorData error={error} /> : ""}
      {iadeyeEsasKDVHesaplama.length > 0 ? (
        <>
          <Typography
            component={"h3"}
            variant={"h5"}
            align="center"
            color="secondary.second"
            mt={2}
          >
            İadeye Esas KDV Hesap Tablosu
          </Typography>
          <Grid container mt={2}>
            <Grid
              item
              md={10}
              sx={{
                border: "1px solid",
                borderColor: "secondary.second",
                p: 1,
                mt: 2,
              }}
            >
              <TableComponent
                data={iadeyeEsasKDVHesaplama}
                tableColumns={iadeyeEsasKDVColumns}
              />
            </Grid>
            <Grid item md={2} mt={2}>
              <GigTable gigOranı={gigOranı} />
            </Grid>
          </Grid>
          <Grid container display={"flex"} justifyContent="end" spacing={1}>
            <Grid item>
              {/* <ExportData
                  url={`api/indirilecek_KDV/get_satis_fat_list/${tckn}?start_date=${dayjs(
                    startDate
                  ).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).format(
                    "YYYY-MM-DD"
                  )}`}
                  dosyaAdi={`satis_Faturalari_listesi-${dayjs(startDate).format(
                    "YYYY-MM-DD"
                  )}-${dayjs(endDate).format("YYYY-MM-DD")}.xlsx`}
                /> */}
            </Grid>
            <Grid item>
              <MyButton
                title={"Kapat"}
                variant="contained"
                color="btnActiveColor"
                onClick={() => setIadeyeEsasKDVHesaplama([])}
              >
                Kapat
              </MyButton>
            </Grid>
          </Grid>
        </>
      ) : errorData ? (
        <ErrorData
          error={
            "Mükellefinize ait İadeye Esas KDV Hesaplaması bulunamadı. İlgili yüklemeleri tamamladıktan sonra tekrar deneyiniz!"
          }
        />
      ) : (
        ""
      )}
    </Box>
  );
};

export default IadeyeEsasKDVHesaplama;
