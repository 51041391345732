import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./app/store";
import AppRouter from "./router/AppRouter";
import React, { useEffect } from "react";
import { Box, CssBaseline } from "@mui/material";

const getDesignTokens = (mode) => ({
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: "#EEEEEE",
          },
          bodyColor: {
            main: "#EEEEEE",
          },
          input: {
            main: "#454F5B",
          },
          secondary: {
            main: "#454F5B",
            second: "#222831",
          },
          tableColor: {
            main: "#EEEEEE",
          },
          btnColor: {
            main: "#454F5B",
          },
          btnActiveColor: {
            main: "#0092CA",
          },
          subTableColor: {
            main: "#f5f5f5",
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: "#454F5B",
          },
          input: {
            main: "white",
          },
          bodyColor: {
            main: "#222831",
          },
          tableColor: {
            main: "#393E46",
          },
          secondary: {
            main: "#454F5B",
            second: "#EEEEEE",
          },
          btnColor: {
            main: "#EEEEEE",
          },
          btnActiveColor: {
            main: "#0092CA",
          },
          subTableColor: {
            main: "#0a0a0a",
          },
        }),
  },
  components: {
    mode,
    ...(mode === "light"
      ? {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#454F5B",
                },

                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#454F5B",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#454F5B",
                },
                "& .css-1n4o6tw-MuiSvgIcon-root": {
                  color: "#454F5B",
                },
              },
              input: {
                color: "#454F5B",
              },
            },
          },
          MuiTableContainer: {
            styleOverrides: {
              root: {
                borderColor: "#454F5B",
                border: "1px solid",
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              root: {
                "& .MuiPaper-root": {
                  backgroundColor: "#EEEEEE",
                },
                "& .MuiDialogTitle-root": {
                  color: "#454F5B !important",
                },
                "& .MuiInputLabel-formControl": {
                  color: "#454F5B !important",
                },
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: "#454F5B",
                "&.Mui-focused": {
                  color: "#454F5B",
                },
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                color: "#454F5B",
                "&.Mui-focused": {
                  color: "#454F5B",
                },
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                color: "#393E46",
              },
            },
          },
        }
      : {
          MuiOutlinedInput: {
            styleOverrides: {
              root: {
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },

                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "white",
                },
                "& .css-1n4o6tw-MuiSvgIcon-root": {
                  color: "white",
                },
              },
              input: {
                color: "white",
              },
            },
          },
          MuiTableContainer: {
            styleOverrides: {
              root: {
                borderColor: "white",
                border: "1px solid",
              },
            },
          },
          MuiDialog: {
            styleOverrides: {
              root: {
                "& .MuiPaper-root": {
                  backgroundColor: "#454F5B",
                },
                "& .MuiDialogTitle-root": {
                  color: "#EEEEEE !important",
                },
                "& .MuiInputLabel-formControl": {
                  color: "#EEEEEE !important",
                },
              },
            },
          },
          MuiFormLabel: {
            styleOverrides: {
              root: {
                color: "#EEEEEE",
                "&.Mui-focused": {
                  color: "#EEEEEE",
                },
              },
            },
          },
          MuiInputLabel: {
            styleOverrides: {
              root: {
                color: "#EEEEEE",
                "&.Mui-focused": {
                  color: "#EEEEEE",
                },
              },
            },
          },
          MuiSvgIcon: {
            styleOverrides: {
              root: {
                color: "#EEEEEE",
              },
            },
          },
        }),
  },
});

function App() {
  const [mode, setMode] = React.useState(
    localStorage.getItem("mode") || "dark"
  );
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  useEffect(() => {
    localStorage.setItem("mode", mode);
  }, [mode]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box
          sx={{
            width: "100%",
            minHeight: "100vh",
            backgroundColor: "bodyColor.main",
          }}
        >
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <AppRouter theme={theme} colorMode={colorMode} />
            </PersistGate>
          </Provider>
        </Box>
      </ThemeProvider>
    </>
  );
}

export default App;
