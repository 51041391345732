import React, { useState } from "react";
import { Grid, Box, Typography, useTheme } from "@mui/material";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "dayjs/locale/tr";
import MyButton from "./MyButton";

const DateComp = ({ getData, title }) => {
  const [year, setYear] = useState(dayjs().year());
  const [month, setMonth] = useState(dayjs().subtract(1, "month").month() + 1);
 const theme = useTheme();
  const handleData = (e) => {
    e.preventDefault()
    getData(year, month);
  };

  return (
    <Grid
      container
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 2,
        pl: 2,
        width: "100%",
        mt: 2,
      }}
    >
      <Grid
        item
        xs={10}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 2,
          border: `1px solid ${theme.palette.secondary.second}`,
          borderRadius: "0.2rem",
          p: 2,
          m: "auto",
        }}
      >
        <Typography color="secondary.second" variant="h6">
          Oluşturmak İstediğiniz {title}ne Ait Dönemi Seçiniz
        </Typography>
        <Box component="form" onSubmit={handleData}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="tr">
            <DemoContainer components={["DatePicker", "DatePicker"]}>
              <DatePicker
                label="Yıl"
                views={["year"]}
                value={dayjs().year(year)}
                onChange={(newValue) => setYear(newValue.year())}
                openTo="year"
                format="YYYY"
              />
              <DatePicker
                views={["month"]}
                label="Ay"
                value={dayjs(new Date(year, month - 1))}
                onChange={(newValue) => setMonth(newValue.month() + 1)}
                openTo="month"
                format="MM"
              />
            </DemoContainer>
          </LocalizationProvider>

          <Box sx={{ width: "25%",mt:2,mx:"auto" }}>
            <MyButton
              variant="contained"
              color="btnActiveColor"
              title="Oluştur"
              type="submit"
              // onClick={handleData}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default DateComp;
