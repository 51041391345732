import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import TableComponent from "./TableComponent";
import { kdvBeyannameColumns } from "../../helper/columns";
import MyButton from "../Commons/MyButton";
import ExportData from "../Commons/ExportData";
import useAxios from "../../hooks/useAxios";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";

const KDVBeyannameOzet = ({ tckn, onClose }) => {
  const [kdvBeyanname, setKdvBeyanname] = useState([]);
  const axiosWithToken = useAxios();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getKDVBeyannameData = async () => {
    setError(false);
    setLoading(true);
    try {
      const { data } = await axiosWithToken.get(
        `api/reader_3/get_kdv_beyanname?vergi_no=${tckn}`
      );
      setKdvBeyanname(data);
    } catch (error) {
      setError(error?.response?.data?.error);
      setKdvBeyanname([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getKDVBeyannameData();
    return () => {
      setKdvBeyanname([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      {loading ? <LoadingData /> : ""}
      {error ? <ErrorData error={error} /> : ""}
      {kdvBeyanname.length > 0 && (
        <>
          <Typography
            component={"h3"}
            variant={"h5"}
            align="center"
            color="secondary.second"
            mt={2}
          >
            KDV Beyanname Özet Tablosu
          </Typography>
          <Box
            sx={{
              border: "1px solid",
              borderColor: "secondary.second",
              p: 1,
            }}
          >
            <TableComponent
              data={kdvBeyanname}
              tableColumns={kdvBeyannameColumns}
            />
            <Grid container display={"flex"} justifyContent="end" spacing={1}>
              <Grid item>
                <ExportData
                  url={`api/reader_3/get_kdv_beyanname_export?vergi_no=${tckn}`}
                  dosyaAdi={`kdv_beyanname_özet-${tckn}.xlsx`}
                />
              </Grid>
              <Grid item>
                <MyButton
                  title={"Kapat"}
                  variant="contained"
                  color="btnActiveColor"
                  onClick={() => {
                    setKdvBeyanname([]);
                    onClose();
                  }}
                >
                  Kapat
                </MyButton>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
};

export default KDVBeyannameOzet;
