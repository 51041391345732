import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";

const KarsitIncelemeInfo = ({ karsitIncelemeInfo, icon }) => {
  const data = {
    "Toplam KDV": karsitIncelemeInfo["toplam_kdv"],
    "KDV 80%": karsitIncelemeInfo["kdv_80_yuzde"],
    "KDV 50%": karsitIncelemeInfo["kdv_50_yuzde"],
    "65000 Tl üstü alımlara dair kdv toplamı":
      karsitIncelemeInfo["65000 Tl üstü alımlara dair kdv toplamı"],
    "Aynı fatura döneminde tek firmadan 195000 Tl'nin üstünde yapılan alımlara dair toplam KDV":
      karsitIncelemeInfo[
        "Aynı fatura döneminde tek firmadan 195000 Tl'nin üstünde yapılan alımlara dair toplam KDV"
      ],
    "Alt ve Üst sınırları aşan faturaların toplam KDV tutarı":
      karsitIncelemeInfo[
        "Alt ve Üst sınırları aşan faturaların toplam KDV tutarı"
      ],
    "Zorunlu olan karşıt inceleme tutanakları dönem içi toplam KDV tutarının %80'inini karşılıyor mu?":
     icon[karsitIncelemeInfo[
        "Zorunlu olan karşıt inceleme tutanakları dönem içi toplam KDV tutarının %80'inini karşılıyor mu?"
      ]],
    "Zorunlu olan karşıt inceleme tutanakları dönem içi toplam KDV tutarının %50'sini karşılıyor mu?":
      icon[karsitIncelemeInfo[
        "Zorunlu olan karşıt inceleme tutanakları dönem içi toplam KDV tutarının %50'siini karşılıyor mu?"
      ]],
  };

  return (
    <TableContainer
      component={Paper}
      color="secondary.second"
      className="myscrool"
      sx={{
        my: 2,
        maxWidth: "100%",
        backgroundColor: "tableColor.main",
        position: "relative",
        minHeight: "500px",
        maxHeight: "500px",
        minWidth: "300px",
        overflowY: "scroll",
      }}
    >
      <Table aria-label="KDV verileri tablosu">
        <TableBody>
          {Object.entries(data).map(([key, value], index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" sx={{display:"flex", justifyContent:"space-between"}}>
                <Typography>{key}</Typography>
                <Typography sx={{fontWeight:"bold"}}>:</Typography>
              </TableCell>
              <TableCell align="center">{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default KarsitIncelemeInfo