import React, { useEffect, useState } from "react";
import MyButton from "../Commons/MyButton";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";

import KDVBeyannameOzet from "../Tables/KDVBeyannameOzet";

const ShowKDVBeyannameOzet = ({ tckn }) => {
  const [show, setShow] = useState(false);
   useEffect(() => {
     setShow(false);
   }, [tckn]);
  return (
    <Box>
      <Grid container>
        <Grid item>
          <MyButton
            variant="contained"
            color="btnActiveColor"
            onClick={()=>setShow(!show)}
            title={"KDV Beyanname Özet"}
          />
        </Grid>
      </Grid>
      {show && <KDVBeyannameOzet tckn={tckn} onClose={()=>setShow(false)} />}
    </Box>
  );
};

export default ShowKDVBeyannameOzet;
