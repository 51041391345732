import { Box, Typography } from '@mui/material';
import React from 'react'

const ErrorData = ({ error }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: 5,
      }}
    >
      <Typography color="error">{error}</Typography>
    </Box>
  );
};

export default ErrorData