import React, { useState } from "react";
import MyButton from "../Commons/MyButton";
import Box from "@mui/material/Box";
import EnvanterTabs from "../Navigation/EnvanterTabs";
import { Grid } from "@mui/material";
import UploadFilesForm from "../Forms/UploadFilesForm";

const EnvanterKaydi = ({ tckn, getIslenmisFaturalar=false }) => {
  const [envanterData, setEnvanterData] = useState({});

  const handleCloseTableCustomer = () => {
    setEnvanterData({});
  };
  return (
    <Box>
      <Grid container>
        <Grid item>
          <UploadFilesForm
            tckn={tckn}
            setData={setEnvanterData}
            title={"Fatura Kaydı Yükle"}
            url="api/reader_3/upload_files"
            secondUrl={"api/reader_3/fatura_list"}
            folderType={"fatura"}
            getIslenmisFaturalar={getIslenmisFaturalar}
          />
        </Grid>
      </Grid>

      {Object.values(envanterData).length > 0 && (
        <>
          <EnvanterTabs envanterData={envanterData} />
          <Grid container justifyContent={"end"}>
            <Grid item>
              <MyButton
                variant="contained"
                color="btnActiveColor"
                onClick={handleCloseTableCustomer}
                title="Kapat"
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default EnvanterKaydi;
