import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
const AuthHeader = () => {
  return (
    <Grid item xs={12} mb={1}>
      <Typography variant="h3" color="secondary.second" align="center">
        Müşavir Online Uygulamasına <br /> Hoş Geldiniz!
      </Typography>
    </Grid>
  );
};

export default AuthHeader;
