import { Box, Typography } from "@mui/material";
import React from "react";

const Loading = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection:"column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height:"70vh",
        p: 2,
      }}
    >
      <Typography color="secondary.second" variant={"h4"} >Sayfamız Yükleniyor...</Typography>
      <div>
      
        <span className="loader"></span>
      </div>
    </Box>
  );
};

export default Loading;
