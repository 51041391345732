import { Box, Grid, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import MyButton from "../Commons/MyButton";
import { notify } from "../../helper/notify";
import useAxios from "../../hooks/useAxios";
import TableComponent from "../Tables/TableComponent";
import { tevkifatliSatisFaturalariListesiColumns } from "../../helper/columns";
import ExportData from "../Commons/ExportData";
import EnvanterKaydi from "../Islemler/EnvanterKaydi";
import KDVBeyannamesiKaydi from "../Islemler/KDVBeyannamesiKaydi";
import DateRangecomp from "../Commons/DateRangecomp";
import LoadingData from "../Commons/LoadingData";
import ErrorData from "../Commons/ErrorData";

const TevkifatliSatisFaturalariListesi = ({ tckn }) => {
  const [
    tevkifatliSatisFaturalariListesi,
    setTevkifatliSatisFaturalariListesi,
  ] = useState([]);
  const [exportDate, setExportDate] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const axiosWithToken = useAxios();

  const getData = async (startDate, endDate) => {
    setError(false);
    if (startDate && endDate) {
      setExportDate({ startDate, endDate });
      setLoading(true);
      setTevkifatliSatisFaturalariListesi([]);
      try {
        const { data } = await axiosWithToken(
          `api/tevkifat_raporu/tevkifat_function?tckn=${tckn}&start_date=${startDate}&end_date=${endDate}`
        );
        setTevkifatliSatisFaturalariListesi(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        setError(error?.response?.data?.error);
        setTevkifatliSatisFaturalariListesi([]);
      }
    } else {
      notify("Lütfen önce tarih aralığı belirleyiniz!", "warning");
    }
  };

  useEffect(() => {
    setTevkifatliSatisFaturalariListesi([]);
  }, [tckn]);

  return (
    <Box sx={{ p: 1 }}>
      <Typography color="error" variant="body2" mb={1}>
        *Eğer daha önce bu işlemleri yapmadıysanız lütfen önce bu işlemleri
        tamamlayınız!
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", mt: 1, gap: 1 }}>
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          <EnvanterKaydi tckn={tckn} />
        </Box>
        <Box
          className="myscrool"
          sx={{ maxWidth: "100%", overflowY: "scroll" }}
        >
          <KDVBeyannamesiKaydi tckn={tckn} />
        </Box>
      </Box>

      <DateRangecomp
        getData={getData}
        title="Tevkifatlı Satış Faturaları Listesi"
      />
      {loading ? <LoadingData /> : ""}
      {error ? <ErrorData error={error} /> : ""}
      {tevkifatliSatisFaturalariListesi.length > 0 ? (
        <Box sx={{
              border: "1px solid",
              borderColor: "secondary.second",
              p: 1,
              mt: 2,
            }}>
          <TableComponent
            data={tevkifatliSatisFaturalariListesi}
            tableColumns={tevkifatliSatisFaturalariListesiColumns}
          />
          <Grid container display={"flex"} justifyContent="end" spacing={1}>
            <Grid item>
              <ExportData
                url={`api/tevkifat_raporu/tevkifat_function?tckn=${tckn}&start_date=${exportDate?.startDate}&end_date=${exportDate?.endDate}`}
                dosyaAdi={`tevkifatliSatisFaturalariListesi-${exportDate?.startDate}-${exportDate?.endDate}.xlsx`}
              />
            </Grid>
            <Grid item>
              <MyButton
                title={"Kapat"}
                variant="contained"
                color="btnActiveColor"
                onClick={() => setTevkifatliSatisFaturalariListesi([])}
              >
                Kapat
              </MyButton>
            </Grid>
          </Grid>
        </Box>
      ) : (
        ""
      )}
    </Box>
  );
};

export default TevkifatliSatisFaturalariListesi;
