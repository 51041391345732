
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { notify } from "../helper/notify";


export const teamMembersApi = createApi({
  reducerPath: "teamMembersApi",
  tagTypes: ["TeamMembers"],
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BASE_URL,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().auth.token;
      if (token) {
        headers.set("Authorization", `Token ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getTeamMembers: builder.query({
      query: () => "api/ekiparkadasi/list_create/",
      providesTags: ["TeamMembers"],
      transformResponse: (response) => {
        return response;
      },
    }),
    postTeamMember: builder.mutation({
      query: (info) => {
        return {
          url: "api/ekiparkadasi/list_create/",
          method: "POST",
          body: info,
        };
      },
      invalidatesTags: ["TeamMembers"],
      transformResponse: (response) => {
        notify(
          `Takım arkadaşınız başarıyla eklendi! Sisteme giriş bilgileri arkadaşınızın mail adresine iletildi!`,
          "success"
        );
        return response;
      },
      transformErrorResponse: (response) => {
        notify(`İşlem başarısız oldu!`, "error");
        return response;
      },
    }),
    deleteTeamMember: builder.mutation({
      query: (id) => ({
        url: `api/ekiparkadasi/detail/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["TeamMembers"],
      transformResponse: (response) => {
        notify(`İşlem başarılı!`, "success");
        return response;
      },
      transformErrorResponse: (response) => {
        notify(`İşlem başarısız oldu!`, "error");
        return response;
      },
    }),
    updateTeamMember: builder.mutation({
      query: (info) => ({
        url: `api/ekiparkadasi/detail/${info.id}/`,
        method: "PUT",
        body: info,
      }),
      invalidatesTags: ["TeamMembers"],
      transformResponse: (response) => {
        notify(`İşlem başarılı!`, "success");
        return response;
      },
      transformErrorResponse: (response) => {
        notify(`İşlem başarısız oldu!`, "error");
        return response;
      },
    }),
  }),
});

export const {
  useGetTeamMembersQuery,
  usePostTeamMemberMutation,
  useDeleteTeamMemberMutation,
  useUpdateTeamMemberMutation,
} = teamMembersApi;
