import React, { useEffect, useState } from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { Box, Typography, TextField, useTheme, Divider } from "@mui/material";
import useAxios from "../../hooks/useAxios";
import { notify } from "../../helper/notify";
import MyButton from "../Commons/MyButton";

export const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#454F5B" : state.isFocused ? "#D3D3D3" : "#fff",
    color: state.isSelected ? "#fff" : "#454F5B",
    padding: 10,
  }),
};

function IkinciTipYuklenim({ currentTCKN, getData, year, month }) {
  const [gigOran, setGigOran] = useState(null);
  const [selectedMaterials, setSelectedMaterials] = useState([]);
  const [materials, setMaterials] = useState([]);
  const axiosWithToken = useAxios();
  const theme = useTheme();

  useEffect(() => {
    const formattedMonth = month.toString().padStart(2, '0'); // Ayı iki basamaklı hale getir
    console.log("TCKN:", currentTCKN, "Year:", year, "Month:", formattedMonth); // Kontrol amaçlı
    if (currentTCKN && year && month) {
      fetchGigOran(currentTCKN, year, formattedMonth);
      fetchMaterials(currentTCKN, year, formattedMonth);
    }
  }, [currentTCKN, year, month]);

  const fetchGigOran = async (tckn, year, month) => {
    console.log("Fetching GİG Oran with:", tckn, year, month); // Kontrol amaçlı
    try {
      const response = await axiosWithToken.get(`/api/yuklenilecek_kdv/get_type_two_invoice/?tckn=${tckn}&year=${year}&month=${month}`);
      const gigOranData = response.data["GİG Oran Tablosu"][0];
      setGigOran(gigOranData["GİG Oran"]);
    } catch (error) {
      console.error("GİG oranı çekilirken bir hata oluştu:", error);
    }
  };

  const fetchMaterials = async (tckn, year, month) => {
    console.log("Fetching Materials with:", tckn, year, month); // Kontrol amaçlı
    try {
      const response = await axiosWithToken.get(`/api/yuklenilecek_kdv/get_type_two_invoice/?tckn=${tckn}&year=${year}&month=${month}`);
      const formattedMaterials = response.data.data.map((item) => ({
        label: `${item.product_name} (${item.invoice__invoice_no}, ${item.quantity}, ${item.invoice__invoice_date})`,
        value: item,
      }));
      setMaterials(formattedMaterials);
    } catch (error) {
      console.error("Malzemeler çekilirken bir hata oluştu:", error);
    }
  };

  const handleMaterialChange = (selectedOptions) => {
    setSelectedMaterials(selectedOptions || []);
  };

  const handleSubmit = async () => {
    if (gigOran === null || gigOran === "") {
      notify("Lütfen GİG oranını giriniz.", "error"); // Uyarı mesajı göster
      return; // Kaydetme işlemini durdur
    }

    const formattedMonth = month.toString().padStart(2, '0'); // Ayı iki basamaklı hale getir

    try {
      const payload = selectedMaterials.map((material) => ({
        invoice_no: material.value.invoice__invoice_no,
        product_name: material.value.product_name,
        period: `${year}${formattedMonth}`, // Dönem formatında gönderiliyor, örneğin: "202307"
        quantity: material.value.quantity,
        gig_rate: gigOran,
      }));

      const response = await axiosWithToken.post(`/api/yuklenilecek_kdv/type_two_record/`, payload);
      notify(response.data?.message, "success");
      setSelectedMaterials([]);
      setGigOran(null);
      
      if (typeof getData === "function") {
        getData(year, month); // Kaydettikten sonra veriyi tekrar al
      }
    } catch (error) {
      console.error("Kaydetme işlemi sırasında hata oluştu:", error);
    }
  };

  const animatedComponents = makeAnimated();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        border: `1px solid ${theme.palette.secondary.second}`,
        borderRadius: "0.2rem",
        p: 2,
        minHeight: "100%",
        backgroundColor: "tableColor.main",
      }}
    >
      <Typography variant="h5" color="secondary.second">
        İkinci Tip Yüklenimler
      </Typography>

      <Divider />
      <Typography variant="h6" color="secondary.second">
        GİG Oranı
      </Typography>
      <TextField
        label="GİG Oranını Ondalık Olarak Giriniz (Örnek: 0.987)"
        type="number"
        size="small"
        value={gigOran || ""}
        onChange={(e) => setGigOran(parseFloat(e.target.value))}
        sx={{ width: "100%", color: "secondary.second" }}
        required
      />

      <Typography variant="h6" color="secondary.second">
        Yüklenim Yapılacak Malzeme
      </Typography>
      <Select
        components={animatedComponents}
        isMulti
        options={materials}
        value={selectedMaterials}
        onChange={handleMaterialChange}
        placeholder="Malzemeleri seçin"
        menuPlacement="top"
        styles={customStyles}
      />

      <Box sx={{ width: "25%", display: "flex", m: "auto" }}>
        <MyButton variant="contained" color="btnActiveColor" onClick={handleSubmit} title="Kaydet" />
      </Box>
    </Box>
  );
}

export default IkinciTipYuklenim;
