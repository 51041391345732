import React, {  useState } from "react";
import MyButton from "../Commons/MyButton";
import Box from "@mui/material/Box";
import { Grid, Typography } from "@mui/material";
import TableComponent from "../Tables/TableComponent";
import { gcbColumns } from "../../helper/columns";
import UploadFilesForm from "../Forms/UploadFilesForm";

const GcbKaydi = ({tckn}) => {
  const [gcbData, setGcbData] = useState([]);
  return (
    <Box>
      <Grid container>
        <Grid item>
          <UploadFilesForm
            tckn={tckn}
            setData={setGcbData}
            url={"api/reader_3/upload_files"}
            secondUrl={"api/indirilecek_KDV/gcb_list"}
            folderType={"gcb"}
            title={"GCB Kaydı Yükle"}
          />
        </Grid>
      </Grid>
      {gcbData.length > 0 && (
        <>
          <Typography
            component={"h3"}
            variant={"h5"}
            align="center"
            color="secondary.second"
            mt={2}
          >
            Gümrük Çıkış Beyannamesi
          </Typography>
          <Box>
            <TableComponent data={gcbData} tableColumns={gcbColumns} />
            <Grid container display={"flex"} justifyContent="end" spacing={1}>
              <Grid item>
                {/* <ExportData
                  url={`api/indirilecek_KDV/get_alislar/${tckn}?start_date=${dayjs(
                    startDate
                  ).format("YYYY-MM-DD")}&end_date=${dayjs(endDate).format(
                    "YYYY-MM-DD"
                  )}`}
                /> */}
              </Grid>
              <Grid item>
                <MyButton
                  title={"Kapat"}
                  variant="contained"
                  color="btnActiveColor"
                  onClick={() => setGcbData([])}
                >
                  Kapat
                </MyButton>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </Box>
  );
}

export default GcbKaydi;