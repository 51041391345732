import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import MyButton from "../Commons/MyButton";
import useAuthCall from "../../hooks/useAuthCall";
import { useSelector } from "react-redux";

const PasswordChangeForm = () => {
  const [open, setOpen] = useState(false);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const {changePassword} = useAuthCall()
  const {currentUser} = useSelector(state => state.auth)


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOldPassword("");
    setNewPassword("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    changePassword({
        email: currentUser.email,
        old_password: oldPassword,
        new_password: newPassword
    })
    setOldPassword("")
    setNewPassword("")
    handleClose();
  };

  return (
    <div>
      <MyButton
        title="Şifre Değiştir"
        variant="contained"
        color="btnActiveColor"
        onClick={handleClickOpen}
      />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Şifre Değiştir</DialogTitle>
        <form onSubmit={handleSubmit}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="old-password"
              label="Eski Şifre"
              type="password"
              fullWidth
              variant="standard"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
            <TextField
              margin="dense"
              id="new-password"
              label="Yeni Şifre"
              type="password"
              fullWidth
              variant="standard"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
              <TextField
              margin="dense"
              id="new-password"
              label="Yeni Şifre Tekrarı"
              type="password"
              fullWidth
              variant="standard"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <MyButton
              title="İptal"
              variant="contained"
              color="btnActiveColor"
              onClick={handleClose}
            />
            <MyButton
              title="Değiştir"
              type="submit"
              variant="contained"
              color="btnActiveColor"
            />
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default PasswordChangeForm;
