import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { Grid } from "@mui/material";
import UploadFilesForm from "../Forms/UploadFilesForm";

import KDVBeyannameOzet from "../Tables/KDVBeyannameOzet";

const KDVBeyannamesiKaydi = ({ tckn, getIadeyeEsasKDV }) => {
  const [kdvBeyanname, setKdvBeyanname] = useState(false);
  useEffect(() => {
    if (kdvBeyanname) {
      getIadeyeEsasKDV();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kdvBeyanname]);
  useEffect(() => {
    setKdvBeyanname(false);
  }, [tckn]);
  return (
    <Box>
      <Grid container>
        <Grid item>
          <UploadFilesForm
            tckn={tckn}
            setData={setKdvBeyanname}
            url="api/reader_3/upload_files"
            secondUrl={"api/reader_3/kdv_beyanname"}
            title="KDV Beyannamesi Kaydı Yükle"
            folderType={"beyanname"}
          />
        </Grid>
      </Grid>
      {kdvBeyanname && (
        <KDVBeyannameOzet tckn={tckn} onClose={() => setKdvBeyanname(false)} />
      )}
    </Box>
  );
};

export default KDVBeyannamesiKaydi;
