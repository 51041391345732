import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { btnStyle } from "./CustomerTable";
import { useDeleteTeamMemberMutation, useGetTeamMembersQuery } from "../../services/teamMembers";

const TeamFriendsTable = ({ handleOpen, setInitialState }) => {
    const { data: teamFriends } = useGetTeamMembersQuery();
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [deleteTeamMember] = useDeleteTeamMemberMutation()
  const [page, setPage] = React.useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      {teamFriends.length > 0 ? (
        <TableContainer
          component={Paper}
          color="secondary.second"
          sx={{
            mt: 1,
            backgroundColor: "tableColor.main",
            color: "secondary.second",
          }}
        >
          <Table
            aria-label="collapsible table"
            sx={{
              color: "secondary.second",
              "& .MuiTableCell-root": {
                color: "secondary.second !important",
              },
              "& .MuiSvgIcon-root": {
                color: "secondary.second !important",
              },
              "& .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar": {
                color: "secondary.second !important",
              },
            }}
          >
            <TableHead>
              <TableRow sx={{ color: "secondary.second" }}>
                <TableCell>Ad-SoyAd</TableCell>
                <TableCell align="center">Telefon</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ color: "secondary.second" }}>
              {teamFriends?.map((row) => (
                <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                  <TableCell component="th" scope="row">
                    {row.ekip_arkadasi_adi_soyadi}
                  </TableCell>
                  <TableCell align="center">{row.telefon}</TableCell>
                  <TableCell align="center">{row.email}</TableCell>
                  <TableCell align="center">
                    <EditIcon
                      sx={btnStyle}
                      onClick={() => {
                        handleOpen();
                        setInitialState(row);
                      }}
                    />
                    <DeleteOutlineIcon
                      sx={btnStyle}
                      onClick={() =>
                        deleteTeamMember(row.id)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={teamFriends.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Satır sayısı:"
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} / ${count !== -1 ? count : `daha fazla ${to}`}`
            }
            sx={{
              color: "secondary.second",
              "& .MuiTablePagination-selectIcon": {
                color: "secondary.second !important",
              },
            }}
          />
        </TableContainer>
      ) : (
        <Typography color="error" variant="h6" mt={2}>
          Henüz ekip arkadaşlarınızı sisteme dahil etmediniz!
        </Typography>
      )}
    </>
  );
};

export default TeamFriendsTable;
