import { createSlice } from "@reduxjs/toolkit";

const authSlice = createSlice({
  name: "auth",

  initialState: {
    currentUser: null,
    userProfile:{},
    loading: false,
    error: false,
    token: null,
  },
  reducers: {
    fetchStart: state => {
      state.loading = true;
      state.error = false;
    },
    registerSuccess: (state,{payload})=>{
      state.loading = false;
      state.currentUser = payload.data.username
      state.token = payload.token
    },
     //! user bilgisi farklı geldiği için ayrı slice açtık
     loginSuccess: (state, { payload }) => {
      state.loading = false;
      state.currentUser = payload;
      state.token = payload.token;
    },
    logoutSuccess: (state) => {
      state.loading = false;
      state.currentUser = null;
      state.token = null;
    },
    updateUserSuccess:(state,{payload})=>{
      state.userProfile = payload;
      state.loading = false;
    },
    fetchFail: state => {
      state.loading = false;
      state.error = true;
    },
  },
});

export const {
  fetchStart,
  registerSuccess,
  logoutSuccess,
  loginSuccess,
  updateUserSuccess,
  fetchFail,
} = authSlice.actions;
export default authSlice.reducer;
