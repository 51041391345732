import { Box, CircularProgress, Typography } from '@mui/material';
import React from 'react'

const LoadingData = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        mt: 5,
      }}
    >
      <Typography color="secondary.second">
        Verileriniz hazırlanıyor...
      </Typography>
      <CircularProgress sx={{ color: "white" }} />
    </Box>
  );
}

export default LoadingData